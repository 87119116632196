import merge from 'deepmerge';

import Types from './classes/types';
import { AutoOrder } from './models/auto-order';
import { MarketTypes } from './models/market/market-types';
import { isDesktop, isMobile, isCustomDesigns, isCustom2Design, isVersion6MobileTarget, isVersion6Design, isVersion6DesktopTarget, isCustom2DesktopTarget, isCustom2MobileTarget } from './utils/platform';

import SvgIcon from './common/components/SvgIcon';

let envConfig = {};

try {
    envConfig = require(`./targets/${process.env.REACT_APP_TARGET_NAME}/AppConfig.${process.env.REACT_APP_CONFIG}`).default;
} catch (e) {}

const AppConfig = merge({
    target: process.env.REACT_APP_TARGET_NAME,
    name: '',
    domain: '',
    isEnableDynamicDomain: false,
    mailSupport: '',
    defaultTradeMode: Types.TRADE_MODE_ADVANCED, // simple_exchange, trade
    // if enable change trade mode this modes is allowed
    allowedTradeModes: [
        Types.TRADE_MODE_ADVANCED,
    ],
    showMarketWatchGroup: true, // показ списка групп в хедере MarketWatch
    showPositionsNettingMenu: true, // показ доп. меню неттинга в таблице позиций
    showCloseAllPositionsOrdersMenu: true, // показ доп. меню в таблице позиций/ордеров
    showDefaultTickerTradePanel: "EUR/USD", // default ticker for show in TradeView
    showTickerChartsForNewUser: ["EUR/USD"], // show this ticker charts for new user
    disableInstrumentsFilter: true, // Отключение фильтрации инструментов
    detectMobileDevices: true, // Редиректить на моб. версию терминала если устройство мобильное
    showOrderBook: true,
    orderBookDepth: (isVersion6Design || isCustom2Design) ? 10 : 4, // number of rows in table
    liveSupportType: 'default',
    googleMapApiToken:'',
    autoLogOutTimeOut:0, // Время автоматического логаута из терминала при бездействии в минутах (0 опция отключена)
    auth2TokenUpdateDiff:60, //количество секунд вычитаемое из времени жизни токена при его обновлении
    dayChangeCurrency: 'USD',
    demoAccountExpirationDays: 0, // Demo account expiration period in days. 0 - expiration disabled
    notificationMessageHideDelay: 3000, // Время скрытия нотификационного сообщения
    collectUpdatesTimeout: 500,
    isDisableServiceWorker: false, // allow service worker. false - allow service

    whatToBuy: {
        popular: [],
        shortListLength: 3,
    },

    walletsSort: {
        direction: Types.SORT_ASC,
        field: Types.FIELD_REG_DATE
    },

    referral: {
        cookieAffiliateCodeName: "jroxjamcom", // Affiliate code
        cookiePartnerLinkName: "partner_link", // partner link
    },

    labelsImg: {
        logo: null,
        logoDark: null,
        logoMobile: null, // логотип моб. версии в "выпадающем" меню
        logoAuth: null, // логотип на странице авторизации
        squareLogo: null, // логотип на боковій панелі version6/desktop
        logoInvestingPage: null, // логотип на странице подписок
    },

    poweredBy: {
        name: '',
        link: '',
        logo: ''
    },

    offByVerifiedKYCGroup: {
        features: {
            isEnabledKYCConfirmationMessages: false,
            isAppropriateTestEnable: false,
            isEnableTierVerification: false,
            isKYCVerifiedPriority: false,
            isEnableCheckTierOnDeposit: false,
            showKYCAlertWhenPositionHasOpened: false,
        }
    },

    authBackgroundImage: "",

    wlMainPage: {
        name: '',
        url: ''
    },

    features: {
        showTradeOnDemo: true, // show modal after registration "Trade on the Demo"
        addFakeExchangeCurrencyAccount: true, // add to accounts list exchange currency fake account
        allowChangeTradeMode: true,
        allowLanguageDetection: false, // allow language detection for new user
        filterMarginModeAccounts: false, // limit accounts list by currency in margin mode
        isEnableUserProfile: false, // show user profile modal
        allowChangePasswordModal: false, // enable change password modal in terminal
        isEnableSubscriptions: false,  // вкл. показа подписок - таблица, вкл. роутера на страницу подписок, сама страница подписок
        isEnableNews: false, // enable news tab
        isEnableActivityLogTab: true, // hide activity log tab from panels
        isEnableMarginHistoryTab: false, // hide margin trade history tab from panels
        isEnablePlatformTutorialsTab: false, // enable platform tutorials tab
        isEnableMarketTab: true, // enable market tab on tabs menu
        isEnableProfileTab: true, // enable profile tab on tabs menu for mobile
        isEnableChartTab: true, // enable chart tab on tabs menu for mobile
        isEnableFavoritesTab: true, // enable favorites tab on tabs menu
        isEnablePositionsTab: true, // enable positions tab on tabs menu for mobile
        isEnableOrdersTab: true, // enable orders tab on tabs menu for mobile
        isEnableHistoryTab: true, // enable history tab on tabs menu for mobile
        isEnableNotificationsTab: true, // enable notifications tab
        isEnableTradesTab: true, // enable trades tab
        isEnableDemoMode: false, // вкл. демо мод для роботы терминала
        isEnableDepositNotification: true, // вкл.-выкл. уведомлений о необходимости депозита при входе в систему и во время торговли (так же уведомление о торговле с демо счета)
        isEnableRegistrationNotification: false, // окно об успешной регистрации
        isEnableDepositTooltip: true, // вкл. -выкл. показ тултипа рядом с кнопкой депозита в хедере указывающей о необходимости депозита
        isEnableButtonMiningMobVersion: false, // вкл.-выкл. в меню сылку на майнинг терминал мобильная версия,
        isEnableButtonExchangeMobVersion: false, // вкл.-выкл. в меню сылку на Exchange Terminal мобильная версия
        isEnableWithdraw: false, // enable Withdraw in terminal
        isEnableMobileWithdraw: true, // enable Withdraw in mobile terminal
        allowCancelTransaction: false, // enable cancel transaction operation
        isEnableDeposit: false, // enable Deposit in terminal
        isEnableEventSound: true, // trigger sound effects on actions
        isEnableGA: false, // enable Google Analytics
        isEnableHotjar: false, // enable hotjar analitics
        useExternalAuth: false, // use login via external auth page (cabinet)
        isEnableAutoLogin: true,
        isEnableLandingAuth: false, // вкл. / выкл. возможность регистрации и входа с лендинга
        isEnabledAuth2: false, // auth with one token after login
        isEnableMobileFastTrade: true, // enable trade by one click from tickers list in mobile version
        isEnableSettingsTab: true, // enable settings tab on tabs menu
        isEnableLiveSupportTab: true, // enable live support tab on tabs menu
        isEnableInvestingTab: false, // enable investment strategies tab on tabs menu
        isEnableExchangeTab: true, // enable exchange tab on tabs menu in simple_exchange mode
        isEnableReferralCabinetTab: false, 
        showMobileOrderDistance: false, // show distance to market instead market price in mobile orders list
        isTickersTreeDefaultOpen: false, // open tickers tree by default
        showDepositIfZeroBalance: false, // show deposit window if accounts balance is zero
        sortCryptocurrenciesByPositionJson: false, // sort tickers cryptocurrencies by Lson file "cryptoListJson"
        sortCustomAccountsHeaderSelect: false, // sort accounts priority by position BTC ETC USDT
        showModalTradeWithoutRiskAfterRegistration: false,
        showAccountTypeInfoModal: false, // show account type info modal after registration
        showBonusCreditsInReport: false,
        showButtonsOfHeader: true, // show Buttons Mining, Exchange, Investing
        showLangInHeader: false, // show language selector in Header
        showContextMenuPositionsTab: false,
        showNonCreatedWallets: true, // in Wallets list show currencies that don't have account
        showWalletFullName: true, // показывать название аккаунта (используется в списке кошельков)
        showInitMarginAsInvested: true, // показывать значение InitMargin вместо MTMargin для позиций в колонке Invested
        isHeaderButtonInfoBaseCurrency: false, // show more info for buttons Deposit in header
        isEnableActivityLog: true, //show activity log menu item
        isEnableRegistration: false,
        isEnabledNewAuth: false,
        isEnabledEmailVerification: false,
        isEnabledNotAuthEmailVerification: true,
        isEnabledNotAuthPhoneVerification: true,
        isEnabledConfirmationMessages: true,
        isEnabledKYCConfirmationMessages: false,
        isEnableOrderBookRandomizer: false,
        isEnableResponsiveMode: true,
        isEnableUserForbiddenPage: false,
        isEnableRealNotExistMessage: false,
        isEnableScreenSharing: true,
        showPoweredBy: false,
        isEnableTierVerification: false,
        isEnableCheckTierOnDeposit:true, //Проверка KYC пользователя при депозите средств
        isEnableDepositBXC: false,
        isDispatcherEnabled: false,
        showKYCAlertWhenPositionHasOpened: false,
        isFacebookLoginEnable:false, //enable facebook login
        isFacebookSingUpEnable:false, //enable facebook sign up
        isGoogleLoginEnable:false, // enable google login
        isGoogleSignUpEnable:false, //enable google sign up
        isEnableTracking: false, // enable terminal tracking mode
        mouseTrackingTimeout: 1000, // таймаут трекинга положения курсора (ms)
        chartTrackingTimeout: 6000, // таймаут трекинга обновления графика
        isEnableWeightedAverageByPrice: false, // включение расчета средневзвешенной цены по стакану для Обменника
        isKYCVerifiedPriority: false, // Проверять только KYCVerified, игнорировать шаги KYC on boarding
        isShowSLTPPrefix: true, // Отображать префикс +- при установки значений SL TP в торговой панели
        isAppropriateTestEnable: true, // Отображать нотификацию о прохождении теста для KYC
        clientWrongTimeGap: 5, // во сколько раз разница в секундах между временем последней записи в истории и временем первой квоты должна превышать длину свечи,  чтобы показать уведомеление пользователю о неправильном локальном врмени (если 0, то не показываем)
        accountHistoryFromReport: true, //true - fetch using http, false - using wss
        autoCurrentAccountCurrency: '',
        currentPriorityAccountCurrencies: [
            'USD',
            'USDT',
            'EUR',
            'BTC',
            'RUB',
        ],
        quoteHistoryFromQuote: false, //true - fetch using http, false - using wss
        isEnableQuoteWSChanel: false,
        isLogoWLClickable: false,
        saveSearchRequests: false, // true - to local storage, false - only state
        logOutReactionTime: 60,
        isOrderBookExpanded: false,
        showCommission: true,
        isCreateFromRealNotExist: false,
        singleInstrumentsList: true,
        isSmsCodeNumeral: true, // true - input sms code in PhoneVerification only digits
    },

    confirmationMessages: {
        messages: []
    },

    loadingSpinner: {
        showPlatformName: !isCustomDesigns
    },

    accountPrefix: {
        [Types.ACCOUNT_TYPE_DEMO]: ['DEMO'],
        [Types.ACCOUNT_TYPE_REAL]: ['REAL'],
        [Types.ACCOUNT_TYPE_PAMM]: ['PAMM'],
        [Types.ACCOUNT_TYPE_MINING]: ['MINING'],
        [Types.ACCOUNT_TYPE_MPF]: ['MPF'],
        [Types.ACCOUNT_TYPE_FAKE]: ['FAKE'],
        [Types.ACCOUNT_TYPE_PROFIT]: ['Profit'],
        [Types.ACCOUNT_TYPE_PAYMENT]: ['Payment'],
        [Types.ACCOUNT_TYPE_AFFILIATE]: ['Affiliate'],
    },

    accountVisible: {
        [Types.ACCOUNT_TYPE_DEMO]: true,
        [Types.ACCOUNT_TYPE_REAL]: true,
        [Types.ACCOUNT_TYPE_PAMM]: true,
        [Types.ACCOUNT_TYPE_MINING]: false,
        [Types.ACCOUNT_TYPE_MPF]: false,
        [Types.ACCOUNT_TYPE_FAKE]: true,
        [Types.ACCOUNT_TYPE_PROFIT]: false,
        [Types.ACCOUNT_TYPE_PAYMENT]: false,
        [Types.ACCOUNT_TYPE_AFFILIATE]: false,
    },

    simplePeer: {
        iceServers: [
            { urls: 'stun:stun.l.google.com:19302' },
            { urls: 'stun:stun1.l.google.com:19302' },
            { urls: 'stun:stun2.l.google.com:19302' },
            { urls: 'stun:stun3.l.google.com:19302' },
            { urls: 'stun:stun4.l.google.com:19302' },
        ]
    },

    settingsGeneral: {
        defaultContractSize: "BASIC", // LOTS/BASIC по умолчанию для новых пользователей в настройках
        defaultMarketModel: MarketTypes.INVESTMENT, // Use Classic or Investment tate model
        disabledConfirmations: {},
        changeContractSize: true,
        changeLanguage: true,
        isShowLayoutButton: false,
        platformNotifications: true,
        saveChartFrame: true,
        tradeSize: 0.001,
        maxTradeSizeInPercents: 90
    },

    //chart: {
    //   theme: Types.CHART_THEME_BLACK,  //CHART_THEME_BLACK CHART_THEME_WHITE
    //},

    transactionServerEndpoints: {  // список операций, которые нужно выполнять не на wdApi, а на transactionServer (если указан TRANSACTION_URL)
        DepositAccount: true,
        CreateAccountCryptoAddress: true,
        TransferMoney: true,
        WithdrawAccount: true,
        CancelPayment: true,
    },

    callBackRequiredFields: [
        "name",
        "phone",
        "email",
    ],

    registration: {
        defaultCurrencyForCreateUserAndAccounts: 'EUR',
        allowCreateDemo: false,
        confirmEmailAutologinTimeout: 10, //seconds
        RESEND_EMAIL_TIME : 20, // seconds
        notAvailableCountries: [ ],
        hideUnavailableCountries: false,
        CreateAutoLoginUrl:  true,
        useServerParamsForCreateAccounts: true, //Not send DEALER_NAME name and currency to server
        onlyLatinChars: false, // @deprecated
        forcePhoneVerification: false, //second step after registration for enter country and phone with sms verification
        enterCountryOnFirstStep: true, // enter country on first step if disable second step (forcePhoneVerification)
        enterPhoneOnFirstStep: true, // enter phone on first step if disable second step (forcePhoneVerification)
        showAffiliateCode: true,
        showPromoCode: true,
        doNotSendRegisterEmail: false,
        stepTypeRegistration: isVersion6Design,

        requiredFieldsInSteps: [
            [
                "Country",
                "PhoneNumber",
                "FirstName",
                "LastName",
            ]
        ],

        loginRequiredFields: [
            "email",
            "password"
        ],

        fields: {
            email: {
                validateParams: {
                    patterns: [],
                    exceptions: [/[~]/],
                }
            }
        },

        // links
        TermsConditionsUrl: '',
        CookiePolicyUrl: '',
        UserAgreementUrl: '',
        UserAgreementUrlRu: '',
        TermsOfUseUrl: '',
        TermsOfUseUrlRu: '',
        PrivacyPolicyUrl: '',
        PrivacyPolicyUrlRu: '',
        RiskDisclosureNoticeUrl: '',
        RiskDisclosureNoticeUrlRu: '',
        ConflictOfInterestPolicyUrl: '',
        ConflictOfInterestPolicyUrlRu: '',
        OrderExecutionPoliceUrl: '',
        OrderExecutionPoliceUrlRu: '',
        RefundPolicyUrl: '',
        RefundPolicyUrlRu: '',
        AntiMoneyLaunderingUrl: '',
        AntiMoneyLaunderingUrlRu: '',
    },

    onBoarding: {
        isShowOnBoardingDialog: false,
        activeSteps: {
            default: [true, true, true, false],
            Tier1: [true, true, true, false],
            Tier2: [true, true, true, true]
        },
        isEnableKYCOnBoardingGoogleAutoComplete: false, // Enable use google autocomplete in KYC onboarding form
        showSupportDialogOnKYC: false, //show support dialog after KYC dialog opened
        isKYCModalStepsFreeChange:false, // заполнение шагов KYC в проивзольном порядке
        checkRiskStatus: true, // Проверять риск статус пользователя
        mergeFilesInPdf: false, //merge upload files in single pdf

      fields: {
          step1: {
              name: {
                  available: false,
                  require: false,
              },
              firstName: {
                  available: true,
                  require: true,
                  validateName: true,
              },
              middleName: {
                  available: false,
                  require: false,
                  validateName: false,
              },
              lastName: {
                  available: true,
                  require: true,
                  validateName: true,
              },
              dateOfBirth: {
                  available: true,
                  require: true,
              },
              countryOfBirth: {
                  available: true,
                  require: true,
              },
              placeOfBirth: {
                  available: true,
                  require: true,
              },
              nationality: {
                  available: true,
                  require: true,
              },
              nationalIdType: {
                  available: true,
                  require: true,
              },
              documentIssuer: {
                  available: true,
                  require: true,
              },
              documentNumber: {
                  available: true,
                  require: true,
              },
              issueDate: {
                  available: true,
                  require: true,
              },
              expiryDate: {
                  available: true,
                  require: true,
              },
              IdFileList:{
                  minValue:1,
              },
              IdYourSelfFileList:{
                  minValue:1,
              },
              linkList: [
                  {
                      available: false,
                      label: '',
                      urls: [
                          {
                              default: '',
                              ru: '',
                          },
                      ]
                  },
              ],
          },
          step2: {
              mobilePhone: {
                  available: true,
                  require: true,
                  minLength:4,
                  minValue:1,
              },
              countryCode: {
                  available: true,
                  require: false,
              },
              countryOfResidence: {
                  available: true,
                  require: true,
              },
              zipCodeHome: {
                  available: true,
                  require: true,
              },
              stateProvinceHome: {
                  available: true,
                  require: false,
              },
              cityHome: {
                  available: true,
                  require: true,
              },
              addressHome: {
                  available: true,
                  require: true,
              },
              countryPostal: {
                  available: true,
                  require: true,
              },
              zipCodePostal: {
                  available: true,
                  require: true,
              },
              stateProvincePostal: {
                  available: true,
                  require: false,
              },
              cityPostal: {
                  available: true,
                  require: true,
              },
              addressPostal: {
              available: true,
              require: true,
              },
              proofFileList:{
                  minValue:1,
              },
          },
          step3: {
              // isShowIncomeAndFinancialStatusLabel: true,
              // isStepColoredWrap: false,
              sourceOfWealth: {
                  available: true,
                  require: true,
              },
              yearlyIncome: {
                  available: true,
                  require: true,
              },
              totalAnnualDeposits: {
                  available: true,
                  require: true,
              },
              totalAnnualWithdrawals: {
                  available: true,
                  require: true,
              },
              currency: {
                  available: true,
                  require: false,
              },
              tab1: {
                  nameOfEmployer: {
                      available: true,
                      require: true,
                  },
                  employerWebSite: {
                      available: true,
                      require: false,
                  },
                  position: {
                      available: true,
                      require: true,
                  },
                  employerAddress: {
                      available: true,
                      require: true,
                  },
              },
              tab2: {
                  yourCompanyName: {
                      available: true,
                      require: true,
                  },
                  webSiteOfCompany: {
                      available: true,
                      require: false,
                  },
                  privateEnterpriseAddress: {
                      available: true,
                      require: true,
                  },
              },
              tab3: {
                  nameOfLastEmployer: {
                      available: true,
                      require: true,
                  },
                  dateOfRetirement: {
                      available: true,
                      require: true,
                  },
              },
              tab4: {
                  originOfFunds: {
                      available: true,
                      require: true,
                  },
              },
              tab5: {
                  specifyYourStatus: {
                      available: true,
                      require: true,
                  },
              },
          },
          step4: {
              uploadedFiles: {
                  minValue: 1,
              }
          }
      }
    },

    historyFilters: {
        period : Types.DATE_PERIOD_LAST_MONTH,
        type: {
            all: true,
            marginal: false,
            exchange: false,
            balance: false,
        },
    },

    history: {
        balanceHistory: {
            fields: {
                transaction: {
                    name: 'Transaction',
                    show: true,
                },
                date: {
                    name: 'Date',
                    show: true,
                },
                walletNumber: {
                    name: 'Wallet',
                    show: true,
                },
                currency: {
                    name: 'Wallet currency',
                    show: true,
                },
                method: {
                    name: 'Method',
                    show: true,
                },
                type: {
                    name: 'Operation type',
                    show: true,
                },
                destinationAddress: {
                    name: 'Destination Address',
                    show: true,
                },
                transactionHash: {
                    name: 'Transaction Hash',
                    show: true,
                },
                final: {
                    name: 'Requested amount',
                    show: true,
                },
                commission: {
                    name: 'Commission',
                    show: true,
                },
                commissionCurrency: {
                    name: 'Commission currency',
                    show: true,
                },
                total: {
                    name: 'Received amount/Withdrawn amount',
                    show: true,
                },
                status: {
                    name: 'Status',
                    show: true,
                },
                comment: {
                    name: 'Comment',
                    show: true,
                },
                iban: {
                    name: 'IBAN',
                    show: true,
                },
                iban2: {
                    name: 'Requisites SBP',
                    show: true,
                },
                network: {
                    name: 'Network',
                    show: true,
                },
                requisites: {
                    name: 'Requisites',
                    show: true,
                },
            },
        },
        exchangeHistory: {
            fields: {
                transaction: {
                    name: 'Transaction',
                    show: true,
                },
                date: {
                    name: 'Date',
                    show: true,
                },
                spentCurrency: {
                    name: 'From wallet',
                    show: true,
                },
                receivedCurrency: {
                    name: 'To wallet',
                    show: true,
                },
                exchangeRate: {
                    name: 'Exchange rate',
                    show: true,
                },
                spent: {
                    name: 'Spent',
                    show: true,
                },
                received: {
                    name: 'Received',
                    show: true,
                },
                commissionCurrency: {
                    name: 'Commission currency',
                    show: true,
                },
                commission: {
                    name: 'Commission',
                    show: true,
                },
            },
        },
        marginHistory: {
            fields: {
                tradeNumber: {
                    name: 'Trade',
                    show: true,
                },
                walletNumber: {
                    name: 'Wallet',
                    show: true,
                },
                walletCurrency: {
                    name: 'Wallet currency',
                    show: true,
                },
                asset: {
                    name: 'Asset',
                    show: true,
                },
                investmentAmount: {
                    name: 'Investment amount',
                    show: true,
                },
                investmentCurrency: {
                    name: 'Investment currency',
                    show: true,
                },
                tradeSize: {
                    name: 'Trade size',
                    show: true,
                },
                tradeCurrency: {
                    name: 'Trade currency',
                    show: true,
                },
                openType: {
                    name: 'Opening type',
                    show: true,
                },
                closeType: {
                    name: 'Close Type',
                    show: true,
                },
                openTime: {
                    name: 'Open Time',
                    show: true,
                },
                closeTime: {
                    name: 'Close Time',
                    show: true,
                },
                orderPrice: {
                    name: 'Open Price',
                    show: true,
                },
                closePrice: {
                    name: 'Close Price',
                    show: true,
                },
                swap: {
                    name: 'Financing',
                    show: true,
                },
                commission: {
                    name: 'Commission',
                    show: true,
                },
                commissionCurrency: {
                    name: 'Commission currency',
                    show: true,
                },
                grossProfit: {
                    name: 'Gross Profit/Loss',
                    show: true,
                },
                grossProfitCurrency: {
                    name: 'Gross Profit/Loss currency short',
                    show: true,
                },
                netProfit: {
                    name: 'Net Profit/Loss',
                    show: true,
                },
                netProfitCurrency: {
                    name: 'Net Profit/Loss currency short',
                    show: true,
                },
                profitability: {
                    name: 'Profitability',
                    show: true,
                },
                comment: {
                    name: 'Comments',
                    show: true,
                }
            },
        },
    },

    callBack: {
        fields: {
            telegram: {
                name: 'Telegram',
                available: true,
                require: true,
            },
            whatsApp: {
                name: 'WhatsApp',
                available: true,
                require: true,
            },
            skype: {
                name: 'Skype',
                available: false,
                require: true,
            },
        },
    },

    personalData: {
        fields: {
            firstName: {
                name: 'FirstName',
                available: true,
                require: true,
                kycField: true,
            },
            lastName: {
                name: 'LastName',
                available: true,
                require: true,
                kycField: true,
            },
            company: {
                name: 'Company',
                available: true,
                require: false,
                kycField: false,
            },
            country: {
                name: 'Country',
                available: true,
                require: true,
                kycField: false,
            },
            state: {
                name: 'State',
                available: true,
                require: false,
                kycField: false,
            },
            tax: {
                name: 'Tax',
                available: true,
                require: false,
                kycField: false,
            },
            city: {
                name: 'City',
                available: true,
                require: true,
                kycField: false,
            },
            address: {
                name: 'Address1',
                available: true,
                require: true,
                kycField: true,
            },
            zipCode: {
                name: 'ZIPCode',
                available: true,
                require: true,
                kycField: false,
            },
            dateOfBirth: {
                name: 'dateOfBirth',
                available: true,
                require: true,
                kycField: false,
            },
        },
        showEmailVerifyStatus: true, // show email verify status icon in profile
        showPhoneVerifyStatus: true, // show phone verify status icon in profile
    },

    sumsubKyc: {
        useSumsubKyc: false,
        levelName: 'basic-kyc-level',
    },

    dateInput: {
        isShowMobilePicker: true,
    },

    changePassword: {
        minLength: 8,
        maxLength: 32,
        patterns: [/[0-9]/, /[a-z]/, /[A-Z]/, /^[0-9a-zA-Z~`' \-.@!%*()_+№:;"<>?]+$/],
        exceptions: [],
    },

    createAccount: {
        allowCreate: {
            [Types.ACCOUNT_DEALER_NAME_REAL]: true,
            [Types.ACCOUNT_DEALER_NAME_DEMO]: true,
        },
        singleRealByCurrency: false,
        allowMultipleDemo: false,
        autoCreateLeverage: '1:100',
        investorLeverage: '1:100',
        leverageList: {
            default: [
                '1:500',
                '1:400',
                '1:200',
                '1:100',
                '1:50',
                '1:20',
                '1:10',
                '1:5',
                '1:1',
            ],
        },
        currencyList: {
            default: ['USD', 'EUR'],
        },
    },

    defines: {
        "CONFIRM_URL": '',
        "SIGNUP_URL": `/signup`,
        "APP_LANG": "en", // на каком языке написано приложение для переводов
        "LANG_URL": process.env.PUBLIC_URL + '/lang/',
        "TIME_OUT_START_CONNECTION": 20000, // ожидание подключения при старте приложения
        "SERVERS_URL": [],
        "REPORT_URL": '',
        "QUOTE_URL": '',
        "TRANSACTION_URL": '', // добавление Транзакшн сервера для некоторых операций
        "AUTH_URL": "/auth",
        "MAIN_URL": "/",
        "MAIN_MOBILE_URL": false,
        "CABINET_URL": "/",
        "EXTERNAL_AUTH_URL": "",
        "WITHDRAW_URL": false,
        "MINING_URL": false,
        "INVESTING_URL": false, // роетер на страницу подписок
        "WELCOME_URL": false,
        "TUTORIALS_URL": false,
        "ACADEMY_URL": false,
        "AFFILIATE_URL": false,
        "DISPATCHER_URL": false,
        "TRACKER_URL": [`ws://127.0.0.1:8894`],
        "REDIRECT_AFTER_AUTOLOGIN": false, // Auto redirect after autologin. Variants false or string of path
        "REDIRECT_AFTER_LANDINGLOGIN": '',
        "DEPOSIT_WITHDRAWALS_INFO_URL": '',
        "INTERCHANGE_DETAILS": '',
        "DEFAULT_LANGUAGE": 'en',
        "HISTORY_URL": "/history",
        "ACTIVITY_LOG_URL": "/activity-log",
        "TRADE_URL": "",
        "REFERRAL_CABINET_LINK": "",
        "EDUCATION_URL": "",
        "DOCUMENTS_URL": "",
        "RSS_NEWS_URL": "getRss.php",
    },

    // Авторизационные данные для демо мода
    demoModeAuthInfo: {
        login: "development3",
        password: "development3",
        "ROOT_SITE_URL": "/" // url сайта с которого пользователь зашел в демо терминал
    },

    soundGroup: {
        [Types.SOUND_GROUP_CLOSE_POSITION]: [Types.EVENT_SOUND_POSITION_CLOSED_PLUS, Types.EVENT_SOUND_POSITION_CLOSED_MINUS],
        [Types.SOUND_GROUP_SUBMIT_MARKET_ORDER]: [Types.EVENT_SOUND_POSITION_OPENED],
        [Types.SOUND_GROUP_SUBMIT_PENDING_ORDER]: [Types.EVENT_SOUND_ORDER_OPENED],
        [Types.SOUND_GROUP_CANCEL_ORDER]: [Types.EVENT_SOUND_POSITION_CLOSED],
    },

    eventSounds: {
        [Types.EVENT_SOUND_DEPOSIT]: {src: 'sounds/deposit.mp3', volume: 0.1},
        [Types.EVENT_SOUND_WITHDRAW]: {src: 'sounds/withdraw.mp3', volume: 0.1},
        [Types.EVENT_SOUND_POSITION_CLOSED]: {src: 'sounds/position_closed.mp3', volume: 0.1},
        [Types.EVENT_SOUND_POSITION_CLOSED_PLUS]: {src: 'sounds/position-close-plus.mp3', volume: 0.1},
        [Types.EVENT_SOUND_POSITION_CLOSED_MINUS]: {src: 'sounds/position-close-minus.mp3', volume: 0.1},
        [Types.EVENT_SOUND_POSITION_OPENED]: {src: 'sounds/position_opened.mp3', volume: 0.1},
        [Types.EVENT_SOUND_ORDER_OPENED]: {src: 'sounds/order-opened.mp3', volume: 0.1},
        [Types.EVENT_SOUND_SWITCH_ON]: {src: 'sounds/switch-on.mp3', volume: 0.1},
        [Types.EVENT_SOUND_SWITCH_OFF]: {src: 'sounds/switch-off.mp3', volume: 0.1},
        [Types.EVENT_SOUND_NOTICE_DEFAULT]: {src: 'sounds/notice-default.mp3', volume: 0.1},
        [Types.EVENT_SOUND_NOTICE_MINOR]: {src: 'sounds/notice-minor.mp3', volume: 0.1},
        [Types.EVENT_SOUND_TABS_1]: {src: 'sounds/tabs-level-1.mp3', volume: 0.1},
        [Types.EVENT_SOUND_TABS_2]: {src: 'sounds/tabs-level-2.mp3', volume: 0.1},
        [Types.EVENT_SOUND_TABS_3]: {src: 'sounds/tabs-level-3.mp3', volume: 0.1},
        [Types.EVENT_SOUND_CLICK]: {src: 'sounds/click.mp3', volume: 0.1}
    },

    exchangeMode: {
        convertCurrencies: [
            'USD',
        ]
    },

    marginMode: {
        visibleAccountCurrencies: [],
    },

    marginAccountCurrencies: [
        'BTC', 'EUR', 'GBP', 'USD'
    ],

    // Payment methods configuration
    paymentMethods: {
        [Types.PAYMENT_TYPE_MIDEX_WIDGET]: {
            id: Types.PAYMENT_TYPE_MIDEX_WIDGET,
            name: 'MidexWidget',
            paymentImg: 'MIDEXPAY',
            depositForm: 'midexWidget',
            merchantKey: '',
            widgetUrl: '',
        },
        [Types.PAYMENT_TYPE_MIDEX_PAYMENT]: {
            id: Types.PAYMENT_TYPE_MIDEX_PAYMENT,
            name: 'MidexPayment',
            paymentImg: 'MIDEXPAY',
            depositForm: 'midexPayment',
            merchantKey: '',
            widgetUrl: '',
        },
        [Types.PAYMENT_TYPE_BABEL]: {
            id: Types.PAYMENT_TYPE_BABEL,
            name: 'Babel',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            currency: 'BAB',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            type: 'Wallet',
            depositForm: 'crypto',
        },
        [Types.PAYMENT_TYPE_BIGXCOIN]: {
            id: Types.PAYMENT_TYPE_BIGXCOIN,
            name: 'BigX Coin',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            currency: 'BXC',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            type: 'Wallet',
            depositForm: 'crypto',
        },
        [Types.PAYMENT_TYPE_BITCOIN]: {
            id: Types.PAYMENT_TYPE_BITCOIN,
            name: 'Bitcoin',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            cryptoAddressType: Types.CRYPTO_ADDRESS_TYPE_BITCOIN,
            currency: 'BTC',
            paymentImg: 'BITCOIN',
            type: 'Wallet',
            depositForm: 'crypto',
            commissionMsg: 'Dear {name}, we would like to inform you that there is a commission charge of 320 Satoshi per byte when you withdraw funds using Bitcoin.',
        },
        [Types.PAYMENT_TYPE_PLC]: {
            id: Types.PAYMENT_TYPE_PLC,
            name: 'PLC',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            currency: 'PLC',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            type: 'Wallet',
            depositForm: 'crypto',
        },
        [Types.PAYMENT_TYPE_BITCOIN_CASH]: {
            id: Types.PAYMENT_TYPE_BITCOIN_CASH,
            name: 'Bitcoin cash',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            currency: 'BCH',
            cryptoAddressType: Types.CRYPTO_ADDRESS_TYPE_BITCOIN_CASH,
            paymentImg: 'BITCOIN_CASH',
            type: 'Wallet',
            depositForm: 'crypto',
        },
        [Types.PAYMENT_TYPE_BITCOIN_GOLD]: {
            id: Types.PAYMENT_TYPE_BITCOIN_GOLD,
            name: 'Bitcoin Gold',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            currency: 'BTG',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            type: 'Wallet',
            depositForm: 'crypto',
        },
        [Types.PAYMENT_TYPE_BITCOIN_SV]: {
            id: Types.PAYMENT_TYPE_BITCOIN_SV,
            name: 'Bitcoin SV',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            currency: 'BSV',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            type: 'Wallet',
            depositForm: 'crypto',
        },
        [Types.PAYMENT_TYPE_DASHCOIN]: {
            id: Types.PAYMENT_TYPE_DASHCOIN,
            name: 'Dashcoin',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            currency: 'DASH',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            type: 'Wallet',
            depositForm: 'crypto',
        },
        [Types.PAYMENT_TYPE_LITECOIN]: {
            id: Types.PAYMENT_TYPE_LITECOIN,
            name: 'Litecoin',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            cryptoAddressType: Types.CRYPTO_ADDRESS_TYPE_LITECOIN,
            currency: 'LTC',
            paymentImg: 'LITECOIN',
            type: 'Wallet',
            depositForm: 'crypto',
            commissionMsg: 'Dear {name}, we would like to inform you that there is a commission charge when you withdraw funds using Litecoin.',
        },
        [Types.PAYMENT_TYPE_EOS]: {
            id: Types.PAYMENT_TYPE_EOS,
            name: 'EOS',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            currency: 'EOS',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            type: 'Wallet',
            depositForm: 'crypto',
        },
        [Types.PAYMENT_TYPE_ETHEREUM]: {
            id: Types.PAYMENT_TYPE_ETHEREUM,
            name: 'Ethereum',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            cryptoAddressType: Types.CRYPTO_ADDRESS_TYPE_ETHEREUM,
            currency: 'ETH',
            paymentImg: 'ETHEREUM',
            type: 'Wallet',
            depositForm: 'crypto',
        },
        [Types.PAYMENT_TYPE_ETHEREUM_CLASSIC]: {
            id: Types.PAYMENT_TYPE_ETHEREUM_CLASSIC,
            name: 'Ethereum Classic',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            currency: 'ETC',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            type: 'Wallet',
            depositForm: 'crypto',
        },
        [Types.PAYMENT_TYPE_OMISEGO]: {
            id: Types.PAYMENT_TYPE_OMISEGO,
            name: 'OmiseGO',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            currency: 'OMG',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            type: 'Wallet',
            depositForm: 'crypto',
        },
        [Types.PAYMENT_TYPE_RIPPLE]: {
            id: Types.PAYMENT_TYPE_RIPPLE,
            name: 'Ripple',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            cryptoAddressType: Types.CRYPTO_ADDRESS_TYPE_RIPPLE,
            currency: 'XRP',
            paymentImg: 'RIPPLE',
            type: 'Wallet',
            depositForm: 'crypto',
        },
        [Types.PAYMENT_TYPE_USDT]: {
            id: Types.PAYMENT_TYPE_USDT,
            name: 'USDT',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            cryptoAddressType: Types.CRYPTO_ADDRESS_TYPE_USDT,
            currency: 'USDT',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            type: 'Wallet',
            depositForm: 'crypto',
        },
        [Types.PAYMENT_TYPE_ZCASH]: {
            id: Types.PAYMENT_TYPE_ZCASH,
            name: 'Zcash',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            currency: 'ZEC',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            type: 'Wallet',
            depositForm: 'crypto',
        },
        [Types.PAYMENT_TYPE_ZRX]: {
            id: Types.PAYMENT_TYPE_ZRX,
            name: '0x',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            currency: 'ZRX',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            type: 'Wallet',
            depositForm: 'crypto',
        },
        [Types.PAYMENT_TYPE_VISA_PAY_BOUTIQUE]: {
            id: Types.PAYMENT_TYPE_VISA_PAY_BOUTIQUE,
            name: 'Visa/Mastercard (Method 1)',
            paymentSystem: Types.PAYMENT_SYSTEM_PAY_BOUTIQUE,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            cryptoAddressType: '',
            paymentCurrencies: [],
            paymentImg: 'VISA_MK',
            type: 'Visa',
            depositForm: 'general',
            commissionMsg: 'Dear {name}, please be informed that withdrawal to credit/debit card is the subject of 4% payment processing fee charged by payment system.',
            terms: {
                minAmountPerTrans: {
                    amount: 1,
                    currency: 'EUR',
                    converted: {
                        amount: 70,
                        currency: 'RUB',
                    },
                },
                maxAmountPerTrans: {
                    amount: 3500,
                    currency: 'EUR',
                    converted: {
                        amount: 250000,
                        currency: 'RUB',
                    },
                },
                maxAmountPerMonth: {
                    amount: 8500,
                    currency: 'EUR',
                    converted: {
                        amount: 600000,
                        currency: 'RUB',
                    },
                },
            },
        },
        [Types.PAYMENT_TYPE_VISA_AON_PAY]: {
            id: Types.PAYMENT_TYPE_VISA_AON_PAY,
            name: 'Visa/Mastercard',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            cryptoAddressType: '',
            paymentCurrencies: [],
            paymentImg: 'VISA_MK',
            type: 'Visa',
            depositForm: 'visa',
            commissionMsg: 'Dear {name}, please be informed that withdrawal to credit/debit card is the subject of 4% payment processing fee charged by payment system.'
        },
        [Types.PAYMENT_TYPE_VISA_MK_MIR]: {
            id: Types.PAYMENT_TYPE_VISA_MK_MIR,
            name: 'Visa/Mastercard/МИР',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            cryptoAddressType: '',
            paymentCurrencies: [],
            paymentImg: 'VISA_MK',
            type: 'Visa',
            disableNoteMsg: true,
        },
        [Types.PAYMENT_TYPE_VISA_EZ_PAY]: {
            id: Types.PAYMENT_TYPE_VISA_EZ_PAY,
            name: 'Visa/Mastercard (Method 3)',
            paymentSystem: Types.PAYMENT_SYSTEM_EZ_PAY,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            cryptoAddressType: '',
            paymentCurrencies: [],
            paymentImg: 'VISA_MK',
            type: 'Visa',
            depositForm: 'visaEzPay',
            commissionMsg: 'Dear {name}, please be informed that withdrawal to credit/debit card is the subject of 4% payment processing fee charged by payment system.',
            terms: {
                minAmountPerTrans: {
                    amount: 5,
                    currency: 'EUR',
                    converted: {
                        amount: 350,
                        currency: 'RUB',
                    },
                },
                maxAmountPerTrans: {
                    amount: 1500,
                    currency: 'EUR',
                    converted: {
                        amount: 100000,
                        currency: 'RUB',
                    },
                },
                maxAmountPerDay: {
                    amount: 4500,
                    currency: 'EUR',
                    converted: {
                        amount: 300000,
                        currency: 'RUB',
                    },
                },
                maxTransPerDay: 3,
            },
        },
        [Types.PAYMENT_TYPE_VISA_DREAMS_PAY]: {
            id: Types.PAYMENT_TYPE_VISA_DREAMS_PAY,
            name: 'Visa/Mastercard (Method 4)',
            paymentSystem: Types.PAYMENT_SYSTEM_DREAMS_PAY,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            cryptoAddressType: '',
            paymentCurrencies: [],
            paymentImg: 'VISA_MK',
            type: 'Visa',
            depositForm: 'visa',
            commissionMsg: 'Dear {name}, please be informed that withdrawal to credit/debit card is the subject of 4% payment processing fee charged by payment system.',
            terms: {
                minAmountPerTrans: {
                    amount: 9,
                    currency: 'EUR',
                    converted: {
                        amount: 600,
                        currency: 'RUB',
                    },
                },
                maxAmountPerTrans: {
                    amount: 15000,
                    currency: 'EUR',
                    converted: {
                        amount: 1000000,
                        currency: 'RUB',
                    },
                },
                maxAmountPerMonth: {
                    amount: 50000,
                    currency: 'EUR',
                    converted: {
                        amount: 3300000,
                        currency: 'RUB',
                    },
                },
                maxTransPerDay: 10,
            },
        },
        [Types.PAYMENT_TYPE_WIRE_TRANSFER]: {
            id: Types.PAYMENT_TYPE_WIRE_TRANSFER,
            name: 'Bank wire transfer',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_WIRE,
            cryptoAddressType: '',
            paymentImg: 'WIRE_TRANSFER',
            type: 'Wire',
            depositForm: 'wire',
        },
        [Types.PAYMENT_TYPE_CRYPTO_NETS]: {
            id: Types.PAYMENT_TYPE_CRYPTO_NETS,
            name: 'USDT',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentImg: 'WIRE_TRANSFER',
            paymentCurrencies: ['USDT TRC20', 'USDT ERC20', 'Solana', 'Ethereum'],
            hidePredefaultAmounts: true,
            randomCurrencyOrder: true,
            hideConverter: true,
            hideCurrencyIcon: true,
        },
        [Types.PAYMENT_PHOENIX_PAYMENTS_LTD]: {
            id: Types.PAYMENT_PHOENIX_PAYMENTS_LTD,
            name: 'Phoenix Payments Ltd ',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_WIRE,
            cryptoAddressType: '',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            type: 'SEPA',
            depositForm: 'phoenix',
        },
        [Types.PAYMENT_TYPE_WEBMONEY]: {
            id: Types.PAYMENT_TYPE_WEBMONEY,
            name: 'WebMoney',
            paymentSystem: Types.PAYMENT_SYSTEM_WEB_MONEY,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            cryptoAddressType: '',
            paymentImg: 'WME',
            type: 'Wallet',
            depositForm: 'webMoney',
        },
        [Types.PAYMENT_TYPE_QIWI]: {
            id: Types.PAYMENT_TYPE_QIWI,
            name: 'СБП',
            paymentSystem: Types.PAYMENT_SYSTEM_QIWI,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            cryptoAddressType: '',
            paymentCurrencies: [],
            paymentImg: 'SBP',
            type: 'Wallet',
            depositForm: 'kiwi',
        },
        [Types.PAYMENT_TYPE_QIWI2]: {
            id: Types.PAYMENT_TYPE_QIWI2,
            name: 'QIWI (Method 2)',
            paymentSystem: Types.PAYMENT_SYSTEM_QIWI2,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            cryptoAddressType: '',
            paymentCurrencies: [],
            paymentImg: 'QIWI',
            type: 'Wallet',
            depositForm: 'kiwi',
            terms: {
                minAmountPerTrans: {
                    amount: 1,
                    currency: 'EUR',
                    converted: {
                        amount: 70,
                        currency: 'RUB',
                    },
                },
                maxAmountPerTrans: {
                    amount: 200,
                    currency: 'EUR',
                    converted: {
                        amount: 15000,
                        currency: 'RUB',
                    },
                },
            },
        },
        [Types.PAYMENT_TYPE_ALFA_CLICK]: {
            id: Types.PAYMENT_TYPE_ALFA_CLICK,
            name: 'AlfaClick',
            paymentSystem: Types.PAYMENT_SYSTEM_ALFA_CLICK,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            cryptoAddressType: '',
            paymentCurrencies: [],
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            type: 'Wallet',
            depositForm: 'general',
            terms: {
                minAmountPerTrans: {
                    amount: 1,
                    currency: 'EUR',
                    converted: {
                        amount: 70,
                        currency: 'RUB',
                    },
                },
                maxAmountPerTrans: {
                    amount: 800,
                    currency: 'EUR',
                    converted: {
                        amount: 60000,
                        currency: 'RUB',
                    },
                },
            },
        },
        [Types.PAYMENT_TYPE_PS_BANK]: {
            id: Types.PAYMENT_TYPE_PS_BANK,
            name: 'Promsvyazbank',
            paymentSystem: Types.PAYMENT_SYSTEM_PS_BANK,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            cryptoAddressType: '',
            paymentCurrencies: [],
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            type: 'Wallet',
            depositForm: 'general',
            terms: {
                minAmountPerTrans: {
                    amount: 1,
                    currency: 'EUR',
                    converted: {
                        amount: 70,
                        currency: 'RUB',
                    },
                },
                maxAmountPerTrans: {
                    amount: 1400,
                    currency: 'EUR',
                    converted: {
                        amount: 100000,
                        currency: 'RUB',
                    },
                },
            },
        },
        [Types.PAYMENT_TYPE_YANDEX_MONEY]: {
            id: Types.PAYMENT_TYPE_YANDEX_MONEY,
            name: 'Yandex.Money (Method 1)',
            paymentSystem: Types.PAYMENT_SYSTEM_YANDEX_MONEY,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            cryptoAddressType: '',
            paymentCurrencies: [],
            paymentImg: 'YANDEX_MONEY',
            type: 'Wallet',
            depositForm: 'kiwi',
        },
        [Types.PAYMENT_TYPE_YANDEX_MONEY2]: {
            id: Types.PAYMENT_TYPE_YANDEX_MONEY2,
            name: 'Yandex.Money (Method 2)',
            paymentSystem: Types.PAYMENT_SYSTEM_YANDEX_MONEY2,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            cryptoAddressType: '',
            paymentCurrencies: [],
            paymentImg: 'YANDEX_MONEY',
            type: 'Wallet',
            depositForm: 'general',
            terms: {
                minAmountPerTrans: {
                    amount: 2,
                    currency: 'EUR',
                    converted: {
                        amount: 140,
                        currency: 'RUB',
                    },
                },
                maxAmountPerTrans: {
                    amount: 800,
                    currency: 'EUR',
                    converted: {
                        amount: 60000,
                        currency: 'RUB',
                    },
                },
                maxAmountPerDay: {
                    amount: 4200,
                    currency: 'EUR',
                    converted: {
                        amount: 300000,
                        currency: 'RUB',
                    },
                },
                maxAmountPerMonth: {
                    amount: 8500,
                    currency: 'EUR',
                    converted: {
                        amount: 600000,
                        currency: 'RUB',
                    },
                },
            },
        },
        [Types.PAYMENT_TYPE_ALI_PAY]: {
            id: Types.PAYMENT_TYPE_ALI_PAY,
            name: 'Alipay',
            paymentSystem: Types.PAYMENT_SYSTEM_ALI_PAY,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            cryptoAddressType: '',
            paymentCurrencies: [],
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            type: 'Wallet',
            depositForm: 'general',
            terms: {
                minAmountPerTrans: {
                    amount: 10,
                    currency: 'EUR',
                    converted: {
                        amount: 78.55,
                        currency: 'CNY',
                    },
                },
                maxAmountPerTrans: {
                    amount: 600,
                    currency: 'EUR',
                    converted: {
                        amount: 4700,
                        currency: 'CNY',
                    },
                },
            },
        },
        [Types.PAYMENT_TYPE_CUP]: {
            id: Types.PAYMENT_TYPE_CUP,
            name: 'CUP',
            paymentSystem: Types.PAYMENT_SYSTEM_CUP,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            cryptoAddressType: '',
            paymentCurrencies: [],
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            type: 'Wallet',
            depositForm: 'general',
        },
        [Types.PAYMENT_TYPE_CERTUS]: {
            id: Types.PAYMENT_TYPE_CERTUS,
            name: 'Certus',
            paymentSystem: Types.PAYMENT_SYSTEM_CERTUS,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            cryptoAddressType: '',
            paymentImg: 'VISA_MK',
            type: 'Visa / Mastercard',
            depositForm: 'general',
        },
        [Types.PAYMENT_TYPE_WE_CHAP]: {
            id: Types.PAYMENT_TYPE_WE_CHAP,
            name: 'WeChat',
            paymentSystem: Types.PAYMENT_SYSTEM_WE_CHAP,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            cryptoAddressType: '',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            type: 'Wallet',
            depositForm: 'general',
            terms: {
                minAmountPerTrans: {
                    amount: 10,
                    currency: 'EUR',
                    converted: {
                        amount: 78.55,
                        currency: 'CNY',
                    },
                },
                maxAmountPerTrans: {
                    amount: 600,
                    currency: 'EUR',
                    converted: {
                        amount: 4700,
                        currency: 'CNY',
                    },
                },
            },
        },
        [Types.PAYMENT_TYPE_MINING_SERVER]: {
            id: Types.PAYMENT_TYPE_MINING_SERVER,
            name: 'Mining Server',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_MINING_SERVER,
            cryptoAddressType: '',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            type: 'Mining',
            depositForm: 'mining',
        },
        [Types.PAYMENT_TYPE_SIMPLEX]: {
            id: Types.PAYMENT_TYPE_SIMPLEX,
            name: 'Simplex',
            paymentSystem: Types.PAYMENT_SYSTEM_SIMPLEX,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            cryptoAddressType: '',
            paymentImg: 'VISA_MK',
            depositForm: 'simplex',
            type: 'Visa / Mastercard',
        },
        [Types.PAYMENT_TYPE_MIDEXPAY]: {
            id: Types.PAYMENT_TYPE_MIDEXPAY,
            name: 'MidexPay',
            paymentSystem: Types.PAYMENT_SYSTEM_SAFECURR,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            cryptoAddressType: '',
            paymentImg: 'VISA_MK',
            type: 'Visa / Mastercard',
            depositForm: 'midexpay',
        },
        [Types.PAYMENT_TYPE_POWERPAY]: {
            id: Types.PAYMENT_TYPE_POWERPAY,
            name: 'PowerPay',
            paymentSystem: Types.PAYMENT_SYSTEM_POWERPAY,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            cryptoAddressType: '',
            paymentImg: 'VISA_MK',
            type: 'Visa / Mastercard',
            depositForm: 'powerpay',
        },
        [Types.PAYMENT_TYPE_POWERPAY_SEPA]: {
            id: Types.PAYMENT_TYPE_POWERPAY_SEPA,
            name: 'SEPA',
            paymentSystem: Types.PAYMENT_SYSTEM_POWERPAY_SEPA,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            cryptoAddressType: '',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            type: 'Transfer',
            depositForm: 'sepa',
        },
        [Types.PAYMENT_TYPE_IBAN]: {
            id: Types.PAYMENT_TYPE_IBAN,
            name: 'IBAN',
            paymentSystem: Types.PAYMENT_SYSTEM_IBAN,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            cryptoAddressType: '',
            paymentImg: 'VISA_MK',
            type: 'Transfer',
            depositForm: 'iban',
        },
        [Types.PAYMENT_TYPE_CLEAR_JUNCTION]: {
            id: Types.PAYMENT_TYPE_CLEAR_JUNCTION,
            name: 'Clear Junction',
            paymentSystem: Types.PAYMENT_SYSTEM_CLEAR_JUNCTION,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            cryptoAddressType: '',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            type: 'SEPA',
            depositForm: 'clearJunction',
        },
        [Types.PAYMENT_TYPE_ADV_CASH]: {
            id: Types.PAYMENT_TYPE_ADV_CASH,
            name: 'AdvCash',
            paymentSystem: Types.PAYMENT_SYSTEM_ADV_CASH,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            // currency: 'BAB',
            paymentImg: 'ADV_CASH',
            type: 'Visa/MС/MIR/AdvCash',
            depositForm: 'powerpay',
        },
        [Types.PAYMENT_TYPE_MIDEXPAY_WIDGET]: {
            id: Types.PAYMENT_TYPE_MIDEXPAY_WIDGET,
            name: 'MidexPay',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            cryptoAddressType: Types.CRYPTO_ADDRESS_TYPE_BITCOIN,
            currency: 'BTC',
            paymentImg: 'MIDEXPAY',
            type: 'Wallet',
            depositForm: 'midexpayWidget',
        },
        [Types.PAYMENT_TYPE_FREE_KASSA]: {
            id: Types.PAYMENT_TYPE_FREE_KASSA,
            name: 'FreeKassa',
            paymentSystem: Types.PAYMENT_SYSTEM_FREE_KASSA,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            paymentImg: 'VISA_MK',
            type: 'Visa / Mastercard',
            depositForm: 'general',
        },
        [Types.PAYMENT_TYPE_INTER_KASSA]: {
            id: Types.PAYMENT_TYPE_INTER_KASSA,
            name: 'InterKassa',
            paymentSystem: Types.PAYMENT_SYSTEM_INTER_KASSA,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            paymentImg: 'VISA_MK',
            type: 'Visa / Mastercard',
            depositForm: 'general',
        },
        [Types.PAYMENT_TYPE_INTER_KASSA_VISA]: {
            id: Types.PAYMENT_TYPE_INTER_KASSA_VISA,
            name: 'Visa',
            paymentSystem: Types.PAYMENT_SYSTEM_INTER_KASSA_VISA,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            paymentImg: 'VISA_MK',
            type: 'Visa / Mastercard',
            depositForm: 'general',
        },
        [Types.PAYMENT_TYPE_INTER_KASSA_MASTERCARD]: {
            id: Types.PAYMENT_TYPE_INTER_KASSA_MASTERCARD,
            name: 'Mastercard',
            paymentSystem: Types.PAYMENT_SYSTEM_INTER_KASSA_MASTERCARD,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            paymentImg: 'VISA_MK',
            type: 'Visa / Mastercard',
            depositForm: 'general',
        },
        [Types.PAYMENT_TYPE_INTER_KASSA_MIR]: {
            id: Types.PAYMENT_TYPE_INTER_KASSA_MIR,
            name: 'Mir',
            paymentSystem: Types.PAYMENT_SYSTEM_INTER_KASSA_MIR,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            paymentImg: 'VISA_MK',
            type: 'Visa / Mastercard',
            depositForm: 'general',
        },
        [Types.PAYMENT_TYPE_PAYEER]: {
            id: Types.PAYMENT_TYPE_PAYEER,
            name: 'Payeer',
            paymentSystem: Types.PAYMENT_SYSTEM_PAYEER,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            paymentImg: 'VISA_MK',
            type: 'Visa / Mastercard',
            depositForm: 'general',
        },
        [Types.PAYMENT_TYPE_ENSOPAYMENT]: {
            id: Types.PAYMENT_TYPE_ENSOPAYMENT,
            name: 'Ensopayment Visa/MC',
            paymentSystem: Types.PAYMENT_SYSTEM_ENSOPAYMENT,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentImg: 'VISA_MK',
            type: 'Visa / Mastercard',
            depositForm: 'general',
            terms: {
                minAmountPerTrans: {
                    amount: 800,
                    currency: 'RUB',
                },
                maxAmountPerTrans: {
                    amount: 50000,
                    currency: 'RUB',
                },
                maxAmountPerDay: {
                    amount: 100000,
                    currency: 'RUB',
                },
            },
        },
        [Types.PAYMENT_TYPE_PAYADVISERS]: {
            id: Types.PAYMENT_TYPE_PAYADVISERS,
            name: 'Payadvisers',
            paymentSystem: Types.PAYMENT_SYSTEM_PAYADVISERS,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentImg: 'VISA_MK',
            type: 'Visa / Mastercard',
            depositForm: 'general',
        },
        [Types.PAYMENT_TYPE_PAYOP]: {
            id: Types.PAYMENT_TYPE_PAYOP,
            name: 'Pay Op',
            paymentSystem: Types.PAYMENT_SYSTEM_PAYOP,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentImg: 'VISA_MK',
            type: "Wallet",
            depositForm: 'general',
        },
        [Types.PAYMENT_TYPE_PAYTHRONE_YANDEX_MONEY]: {
            id: Types.PAYMENT_TYPE_PAYTHRONE_YANDEX_MONEY,
            name: 'Paythrone yandex money',
            paymentSystem: Types.PAYMENT_SYSTEM_PAYTHRONE_YANDEX_MONEY,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            paymentImg: 'YANDEX_MONEY',
            depositForm: 'kiwi',
        },
        [Types.PAYMENT_TYPE_BETATRANSFER]: {
            id: Types.PAYMENT_TYPE_BETATRANSFER,
            name: 'Betatransfer',
            paymentSystem: Types.PAYMENT_SYSTEM_BETATRANSFER,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentImg: 'VISA_MK',
            depositForm: 'general',
            disableNoteMsg: false,
            importantMessages: [],
            showAdditionalFields: false
        },
        [Types.PAYMENT_TYPE_INTERNETCASHBANK]: {
            id: Types.PAYMENT_TYPE_INTERNETCASHBANK,
            name: 'InternetCashBank',
            paymentSystem: Types.PAYMENT_SYSTEM_INTERNETCASHBANK,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentImg: 'VISA_MK',
            depositForm: 'visa',
        },
        [Types.PAYMENT_TYPE_PORTMONE]: {
            id: Types.PAYMENT_TYPE_PORTMONE,
            name: 'Portmone',
            paymentSystem: Types.PAYMENT_SYSTEM_PORTMONE,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentImg: 'VISA_MK',
            depositForm: 'visa',
        },
        [Types.PAYMENT_TYPE_UAPAYUA]: {
            id: Types.PAYMENT_TYPE_UAPAYUA,
            name: 'Uapayua',
            paymentSystem: Types.PAYMENT_SYSTEM_UAPAYUPA,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentImg: 'VISA_MK',
            depositForm: 'visa',
        },
        [Types.PAYMENT_TYPE_PALMIRA]: {
            id: Types.PAYMENT_TYPE_PALMIRA,
            name: 'Palmira',
            paymentSystem: Types.PAYMENT_SYSTEM_PALMIRA,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentImg: 'VISA_MK',
            depositForm: 'visa',
        },
        [Types.PAYMENT_TYPE_ROCKETBIT]: {
            id: Types.PAYMENT_TYPE_ROCKETBIT,
            name: 'Rocketbit',
            paymentSystem: Types.PAYMENT_SYSTEM_ROCKETBIT,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            paymentCurrencies: ['BTC'],
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            depositForm: 'rocketbit',
            terms: {
                minAmountPerTrans: {
                    amount: 500,
                    currency: 'EUR',
                },
            },
        },
        [Types.PAYMENT_TYPE_EASYPORTAL]: {
            id: Types.PAYMENT_TYPE_EASYPORTAL,
            name: 'Easyportal',
            paymentSystem: Types.PAYMENT_SYSTEM_EASYPORTAL,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            paymentCurrencies: ['BTC'],
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            depositForm: 'rocketbit',
            terms: {
                minAmountPerTrans: {
                    amount: 500,
                    currency: 'EUR',
                },
            },
        },
        [Types.PAYMENT_TYPE_IQUICK]: {
            id: Types.PAYMENT_TYPE_IQUICK,
            name: 'Iquick',
            paymentUrl: 'https://iquick.io',
            paymentImg: 'BITCOIN',
            currency: 'BTC',
            type: 'Wallet',
            depositForm: 'iquick',
        },
        [Types.PAYMENT_TYPE_STARBILL]: {
            id: Types.PAYMENT_TYPE_STARBILL,
            name: 'Starbill',
            paymentSystem: Types.PAYMENT_SYSTEM_STARBILL,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentImg: 'VISA_MK',
            depositForm: 'general',
        },
        [Types.PAYMENT_TYPE_STARBILL2]: {
            id: Types.PAYMENT_TYPE_STARBILL2,
            name: 'Starbill 2',
            paymentSystem: Types.PAYMENT_SYSTEM_STARBILL2,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentImg: 'VISA_MK',
            depositForm: 'general',
        },
        [Types.PAYMENT_TYPE_STARBILL3]: {
            id: Types.PAYMENT_TYPE_STARBILL3,
            name: 'Starbill 3',
            paymentSystem: Types.PAYMENT_SYSTEM_STARBILL3,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentImg: 'VISA_MK',
            depositForm: 'general',
        },
        [Types.PAYMENT_TYPE_VISA_MASTERCARD_QIWI]: {
            id: Types.PAYMENT_TYPE_VISA_MASTERCARD_QIWI,
            name: 'Visa/MasterCard (Qiwi)',
            paymentUrl: '',
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentImg: 'VISA_MK',
            depositForm: 'visaMasterCardQiwi',
        },
        [Types.PAYMENT_TYPE_WALLETIX]: {
            id: Types.PAYMENT_TYPE_WALLETIX,
            name: 'Crypto Purchase',
            paymentSystem: Types.PAYMENT_SYSTEM_WALLETIX,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            paymentCurrencies: ['BTC'],
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            depositForm: 'rocketbit',
            terms: {
                minAmountPerTrans: {
                    amount: 500,
                    currency: 'EUR',
                },
            },
        },
        [Types.PAYMENT_TYPE_ALFA_CASH]: {
            id: Types.PAYMENT_TYPE_ALFA_CASH,
            name: 'ALFA CASH',
            paymentUrl: 'https://alfa.cash/register',
            paymentImg: 'ALFA_CASH',
            currency: 'BTC',
            depositForm: 'redirect',
        },
        [Types.PAYMENT_TYPE_ADV_CASH_REDIRECT]: {
            id: Types.PAYMENT_TYPE_ADV_CASH_REDIRECT,
            name: 'ADVCASH',
            paymentUrl: 'https://wallet.advcash.com/register',
            paymentImg: 'ADV_CASH',
            currency: 'BTC',
            depositForm: 'redirect',
        },
        [Types.PAYMENT_TYPE_GOOGLE_PAY]: {
            id: Types.PAYMENT_TYPE_GOOGLE_PAY,
            name: 'Google Pay',
            paymentUrl: '',
            paymentImg: 'I_GOOGLE',
            disabled: true
        },
        [Types.PAYMENT_TYPE_APPLE_PAY]: {
            id: Types.PAYMENT_TYPE_APPLE_PAY,
            name: 'Apple Pay',
            paymentUrl: '',
            paymentImg: 'APPLE_PAY',
            disabled: true
        },
        [Types.PAYMENT_TYPE_QIWI_REDIRECT]: {
            id: Types.PAYMENT_TYPE_QIWI_REDIRECT,
            name: 'QIWI PAY',
            paymentUrl: '',
            paymentImg: 'QIWI',
            currency: 'BTC',
            depositForm: 'redirect',
        },
        [Types.PAYMENT_TYPE_PAYEER_REDIRECT]: {
            id: Types.PAYMENT_TYPE_PAYEER_REDIRECT,
            name: 'PAYEER',
            paymentUrl: 'https://payeer.com/auth/?register=yes',
            paymentImg: 'VISA_MK',
            currency: 'BTC',
            depositForm: 'redirect',
        },
        [Types.PAYMENT_TYPE_BTQ_FINANCE]: {
            id: Types.PAYMENT_TYPE_BTQ_FINANCE,
            name: 'BTQfinance',
            paymentSystem: Types.PAYMENT_SYSTEM_BTQ_FINANCE,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentImg: 'VISA_MK',
            depositForm: 'btqFinance',
        },
        [Types.PAYMENT_TYPE_STRIPE]: {
            id: Types.PAYMENT_TYPE_STRIPE,
            name: 'Stripe',
            depositForm: 'stripe',
            paymentCurrencies: ['GBP'],
            paymentUrls: {},
            paymentImg: 'VISA_MK',
        },
        [Types.PAYMENT_TYPE_NEW_QIWI]: {
            id: Types.PAYMENT_TYPE_NEW_QIWI,
            name: 'QIWI',
            depositForm: 'general',
            paymentUrl: 'https://qiwi.com/',
            paymentImg: 'QIWI',
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            paymentSystem: Types.PAYMENT_SYSTEM_NEW_PAY,
            description: "QIWI is a simple payment service that includes the world's largest ATM network, and has a web version and a mobile application."
        },
        [Types.PAYMENT_TYPE_NEW_BINANCE]: {
            id: Types.PAYMENT_TYPE_NEW_BINANCE,
            name: 'Binance',
            depositForm: 'general',
            paymentUrl: 'https://binance.com/',
            paymentImg: 'VISA_MK',
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            paymentSystem: Types.PAYMENT_SYSTEM_NEW_PAY,
            description: "Binance is a cryptocurrency exchange which is currently the largest exchange in the world in terms of daily trading volume of cryptocurrencies."
        },
        [Types.PAYMENT_TYPE_NEW_PAYEER]: {
            id: Types.PAYMENT_TYPE_NEW_PAYEER,
            name: 'Payeer',
            depositForm: 'general',
            paymentUrl: 'https://payeer.com/',
            paymentImg: 'PAYEER',
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            paymentSystem: Types.PAYMENT_SYSTEM_NEW_PAY,
            description: "Worldwide payments in easy way."
        },
        [Types.PAYMENT_TYPE_NEW_BESTCHANGE]: {
            id: Types.PAYMENT_TYPE_NEW_BESTCHANGE,
            name: 'BestChange',
            depositForm: 'general',
            paymentUrl: 'https://www.bestchange.com/',
            paymentImg: 'BESTCHANGE',
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            paymentSystem: Types.PAYMENT_SYSTEM_NEW_PAY,
            description: "Service, which monitors exchange rates in several hundred reliable exchangers and equips you with all the information you need to select the best exchanger to transfer your money."
        },
        [Types.PAYMENT_TYPE_NEW_VISA]: {
            id: Types.PAYMENT_TYPE_NEW_VISA,
            name: 'Visa',
            depositForm: 'general',
            paymentImg: 'VISA_FAKE',
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            paymentSystem: Types.PAYMENT_SYSTEM_NEW_PAY,
            description: "Visa is a global payment system that allows consumers to make electronic payments"
        },
        [Types.PAYMENT_TYPE_NEW_MASTERCARD]: {
            id: Types.PAYMENT_TYPE_NEW_MASTERCARD,
            name: 'Mastercard',
            depositForm: 'general',
            paymentImg: 'MASTERCARD',
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            paymentSystem: Types.PAYMENT_SYSTEM_NEW_PAY,
            description: "Mastercard is a global payment system that allows consumers to make electronic payments"
        },
        [Types.PAYMENT_TYPE_NEW_YANDEX_MONEY]: {
            id: Types.PAYMENT_TYPE_NEW_YANDEX_MONEY,
            name: 'Yandex.Money',
            depositForm: 'general',
            paymentImg: 'YANDEX_MONEY_FAKE',
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            paymentSystem: Types.PAYMENT_SYSTEM_NEW_PAY,
            description: "Yandex.Money is a universal payment system of the Russian portal, which is very common among users of Russia"
        },
        [Types.PAYMENT_TYPE_NEW_WEBMONEY]: {
            id: Types.PAYMENT_TYPE_NEW_WEBMONEY,
            name: 'WebMoney',
            depositForm: 'general',
            paymentImg: 'WEBMONEY',
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            paymentSystem: Types.PAYMENT_SYSTEM_NEW_PAY,
            description: "WebMoney is a global settlement system and environment for online business activities"
        },
        [Types.PAYMENT_TYPE_NEW_MIR]: {
            id: Types.PAYMENT_TYPE_NEW_MIR,
            name: 'Mir',
            depositForm: 'general',
            paymentImg: 'MIR',
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            paymentSystem: Types.PAYMENT_SYSTEM_NEW_PAY,
            description: "Mir is a Russian national payment system"
        },
        [Types.PAYMENT_TYPE_NEW_CARD_QIWI]: {
            id: Types.PAYMENT_TYPE_NEW_CARD_QIWI,
            name: 'QIWI Card',
            depositForm: 'fakeCard',
            paymentImg: 'QIWI',
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            paymentSystem: Types.PAYMENT_SYSTEM_NEW_PAY,
            successMessage: "Your request for wallet deposit was received. Your personal manager will contact you shortly"
        },
        [Types.PAYMENT_TYPE_NEW_CARD_BINANCE]: {
            id: Types.PAYMENT_TYPE_NEW_CARD_BINANCE,
            name: 'Binance Card',
            depositForm: 'fakeCard',
            paymentImg: 'VISA_MK',
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            paymentSystem: Types.PAYMENT_SYSTEM_NEW_PAY,
            successMessage: "Your request for wallet deposit was received. Your personal manager will contact you shortly"
        },
        [Types.PAYMENT_TYPE_NEW_CARD_PAYEER]: {
            id: Types.PAYMENT_TYPE_NEW_CARD_PAYEER,
            name: 'Payeer Card',
            depositForm: 'fakeCard',
            paymentImg: 'PAYEER',
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            paymentSystem: Types.PAYMENT_SYSTEM_NEW_PAY,
            successMessage: "Your request for wallet deposit was received. Your personal manager will contact you shortly"
        },
        [Types.PAYMENT_TYPE_HR_24]: {
            id: Types.PAYMENT_TYPE_HR_24,
            name: 'HR-24',
            paymentSystem: Types.PAYMENT_SYSTEM_HR_24,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentImg: 'VISA_MK',
            depositForm: 'general',
        },
        [Types.PAYMENT_TYPE_USDT_TRC20]: {
            id: Types.PAYMENT_TYPE_USDT_TRC20,
            name: 'Tether TRC-20 (USDT)',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            currency: 'USDT_TRC20',
            paymentImg: 'USDT',
            type: 'Wallet',
            depositForm: 'crypto',
            warningMsg: 'Make sure, that you make the transaction in the TRON network! If you make the transaction in other network your funds will NOT be credited to your account!',
        },
        [Types.PAYMENT_TYPE_FINANIC_IO]: {
            id: Types.PAYMENT_TYPE_FINANIC_IO,
            name: 'finanic.io',
            paymentSystem: Types.PAYMENT_SYSTEM_FINANIC_IO,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentImg: 'VISA_MK',
            depositForm: 'general',
            paymentCurrencies: []
        },
        [Types.PAYMENT_TYPE_PAY_POUND]: {
            id: Types.PAYMENT_TYPE_PAY_POUND,
            name: 'PayPound',
            paymentSystem: Types.PAYMENT_SYSTEM_PAY_POUND,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentImg: 'VISA_MK',
            depositForm: 'payPound',
            paymentCurrencies: ['EUR', 'USD']
        },
        [Types.PAYMENT_TYPE_PR_MONEY]: {
            id: Types.PAYMENT_TYPE_PR_MONEY,
            name: 'PrMoney',
            paymentSystem: Types.PAYMENT_SYSTEM_PR_MONEY,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentImg: 'VISA_MK',
            depositForm: 'payPound'
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_AAVE]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_AAVE,
            name: 'AAVE',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'ERC20',
            tag: false,
            currency: 'AAVE',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_ADA]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_ADA,
            name: 'ADA',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'CARDANO',
            tag: false,
            currency: 'ADA',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_ALGO]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_ALGO,
            name: 'ALGO',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'ALGO',
            tag: true,
            currency: 'ALGO',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_APE]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_APE,
            name: 'apecoin',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'Erc20',
            tag: false,
            currency: 'APE',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_ATOM]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_ATOM,
            name: 'ATOM',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'BSC',
            tag: false,
            currency: 'ATOM',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_AUDIO]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_AUDIO,
            name: 'AUDIO',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'ERC20',
            tag: false,
            currency: 'AUDIO',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_AVAX]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_AVAX,
            name: 'AVALANCHE',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'C CHAIN',
            tag: false,
            currency: 'AVAX',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_AXS]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_AXS,
            name: 'axs',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'Erc20',
            tag: false,
            currency: 'AXS',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_BCH]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_BCH,
            name: 'BCH',
            paymentImg: 'BITCOIN_CASH',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'BITCOIN CASH',
            tag: false,
            currency: 'BCH',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_BTC]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_BTC,
            name: 'BTC',
            paymentImg: 'BITCOIN',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'BTC STANDART LEGACY',
            tag: false,
            currency: 'BTC',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_BTTC]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_BTTC,
            name: 'BTTC',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'BSC',
            tag: false,
            currency: 'BTTC',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_CAKE]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_CAKE,
            name: 'CAKE',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'BSC',
            tag: false,
            currency: 'CAKE',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_CHZ]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_CHZ,
            name: 'CHZ',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'ERC20',
            tag: false,
            currency: 'CHZ',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_DAI]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_DAI,
            name: 'dai',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'Erc20',
            tag: false,
            currency: 'DAI',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_DSH]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_DSH,
            name: 'DASH',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'DASH',
            tag: false,
            currency: 'DASH',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_DOGE]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_DOGE,
            name: 'DOGECOIN',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'DOGE',
            tag: false,
            currency: 'DOGE',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_EOS]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_EOS,
            name: 'EOS',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'EOS',
            tag: true,
            currency: 'EOS',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_ETC]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_ETC,
            name: 'ETC',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'ERC20',
            tag: false,
            currency: 'ETC',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_ETH]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_ETH,
            name: 'ETH',
            paymentImg: 'ETHEREUM',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'ERC20',
            tag: false,
            currency: 'ETH',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_GALA]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_GALA,
            name: 'GALA',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'ERC20',
            tag: false,
            currency: 'GALA',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_IOTA]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_IOTA,
            name: 'IOTA',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'BSC',
            tag: false,
            currency: 'IOTA',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_LINK]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_LINK,
            name: 'LINK',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'ERC20',
            tag: false,
            currency: 'LINK',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_LTC]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_LTC,
            name: 'LTC',
            paymentImg: 'LITECOIN',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'LTC LEGACY',
            tag: false,
            currency: 'LTC',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_LUNA]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_LUNA,
            name: 'LUNA',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'TERRA LUNA',
            tag: true,
            currency: 'LUNA',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_MKR]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_MKR,
            name: 'Maker',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'Erc20',
            tag: false,
            currency: 'MKR',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_MATIC]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_MATIC,
            name: 'MATIC',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'ERC20',
            tag: false,
            currency: 'MATIC',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_MANA]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_MANA,
            name: 'MANA',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'ERC20',
            tag: false,
            currency: 'MANA',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_ONE]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_ONE,
            name: 'ONE',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'BSC',
            tag: false,
            currency: 'ONE',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_DOT]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_DOT,
            name: 'POLKADOT',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'DOT',
            tag: false,
            currency: 'DOT',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_RUNE]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_RUNE,
            name: 'RUNE',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'BSC',
            tag: false,
            currency: 'RUNE',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_SAND]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_SAND,
            name: 'SAND',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'ER20',
            tag: false,
            currency: 'SAND',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_SHIB]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_SHIB,
            name: 'SHIBA INU',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'ERC20',
            tag: false,
            currency: 'SHIB',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_SOL]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_SOL,
            name: 'Solana',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'sol',
            tag: false,
            currency: 'SOL',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_TRX]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_TRX,
            name: 'TRON',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'TRC20',
            tag: false,
            currency: 'TRX',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_UNI]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_UNI,
            name: 'TUNI',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'Erc20',
            tag: false,
            currency: 'UNI',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_USDT_ERC20]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_USDT_ERC20,
            name: 'USDT ERC20',
            paymentImg: 'USDT',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'ERC20',
            tag: false,
            currency: 'USDT_ERC20',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_USDT_TRC20]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_USDT_TRC20,
            name: 'USDT TRC20',
            paymentImg: 'USDT',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'TRC20',
            tag: false,
            currency: 'USDT_TRC20',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_XLM]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_XLM,
            name: 'XLM',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'STELLAR',
            tag: true,
            currency: 'XLM',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_XRP]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_XRP,
            name: 'XRP',
            paymentImg: 'RIPPLE',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'XRP',
            tag: true,
            currency: 'XRP',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_FIREBLOCKS_XTZ]: {
            id: Types.PAYMENT_TYPE_FIREBLOCKS_XTZ,
            name: 'xtz',
            paymentImg: 'TAB_ACCOUNT_HISTORY',
            paymentSystem: Types.PAYMENT_SYSTEM_DEFAULT,
            paymentMethod: Types.PAYMENT_METHOD_CRYPTO,
            paymentGate: 'Fireblocks',
            depositForm: 'fireblocks',
            network: 'Tezos',
            tag: false,
            currency: 'XTZ',
            showCommission: true
        },
        [Types.PAYMENT_TYPE_AZUL_PAY]: {
            id: Types.PAYMENT_TYPE_AZUL_PAY,
            name: 'Azulpay',
            paymentSystem: Types.PAYMENT_SYSTEM_AZUL_PAY,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentImg: 'VISA_MK',
            depositForm: 'payPound',
            paymentCurrencies: ['USD']
        },
        [Types.PAYMENT_TYPE_GROW_PAY]: {
            id: Types.PAYMENT_TYPE_GROW_PAY,
            name: 'GrowPay',
            paymentSystem: Types.PAYMENT_SYSTEM_GROW_PAY,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentImg: 'VISA_MK',
            depositForm: 'payPound',
            paymentCurrencies: []
        },
        [Types.PAYMENT_TYPE_BETATRANSFER_QIWI]: {
            id: Types.PAYMENT_TYPE_BETATRANSFER_QIWI,
            name: 'Betatransfer(Qiwi)',
            paymentSystem: Types.PAYMENT_SYSTEM_BETATRANSFER_QIWI,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentImg: 'VISA_MK',
            depositForm: 'general',
        },
        [Types.PAYMENT_TYPE_BETATRANSFER_YOOMONEY]: {
            id: Types.PAYMENT_TYPE_BETATRANSFER_YOOMONEY,
            name: 'Betatransfer(YooMoney)',
            paymentSystem: Types.PAYMENT_SYSTEM_BETATRANSFER_YOOMONEY,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentImg: 'VISA_MK',
            depositForm: 'general',
        },
        [Types.PAYMENT_TYPE_BETATRANSFER_P2R]: {
            id: Types.PAYMENT_TYPE_BETATRANSFER_P2R,
            name: 'Betatransfer(P2R)',
            paymentSystem: Types.PAYMENT_SYSTEM_BETATRANSFER_P2R,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentImg: 'VISA_MK',
            depositForm: 'general',
        },
        [Types.PAYMENT_TYPE_VILPAY]: {
            id: Types.PAYMENT_TYPE_VILPAY,
            name: 'Vilpay',
            paymentSystem: Types.PAYMENT_SYSTEM_VILPAY,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentImg: 'VISA_MK',
            depositForm: 'general',
            paymentCurrencies: ["USD"]
        },
        [Types.PAYMENT_TYPE_WELLCOINEX]: {
            id: Types.PAYMENT_TYPE_WELLCOINEX,
            name: 'Wellcoinex',
            paymentSystem: Types.PAYMENT_SYSTEM_WELLCOINEX,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentImg: 'VISA_MK',
            depositForm: 'general',
            paymentCurrencies: ["RUB"]
        },
        [Types.PAYMENT_TYPE_TRANSFER_MONEY]: {
            id: Types.PAYMENT_TYPE_TRANSFER_MONEY,
            name: 'Internal transfer',
            availableCurrencies: [],
            paymentImg: 'TRANSFER_MONEY',
            message: 'When you transfer money between wallets in different currencies, the sum of transferred funds may differ, as there is an automatic currency conversion at the exchange rate at the moment of transfer.'
        },
        [Types.PAYMENT_TYPE_FIREPAY]: {
            id: Types.PAYMENT_TYPE_FIREPAY,
            name: 'FirePay',
            paymentSystem: Types.PAYMENT_SYSTEM_FIREPAY,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentImg: 'VISA_MK',
            depositForm: 'general',
        },
        [Types.PAYMENT_TYPE_PAYPORT]: {
            id: Types.PAYMENT_TYPE_PAYPORT,
            name: 'Visa/Mastercard/МИР',
            paymentSystem: Types.PAYMENT_SYSTEM_PAYPORT,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentImg: 'VISA_MK',
            depositForm: 'general',
            terms: {},
        },
        [Types.PAYMENT_TYPE_EXPAY]: {
            id: Types.PAYMENT_TYPE_EXPAY,
            name: 'ExPay',
            paymentSystem: Types.PAYMENT_SYSTEM_EXPAY,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentImg: 'VISA_MK',
            depositForm: 'general',
        },
        [Types.PAYMENT_TYPE_PAY2PAY]: {
            id: Types.PAYMENT_TYPE_PAY2PAY,
            name: 'Visa/Mastercard',
            paymentSystem: Types.PAYMENT_SYSTEM_PAY2PAY,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentImg: 'VISA_MK',
            depositForm: 'general',
        },
        [Types.PAYMENT_TYPE_AIFORY]: {
            id: Types.PAYMENT_TYPE_AIFORY,
            name: 'Aifory',
            paymentSystem: Types.PAYMENT_SYSTEM_AIFORY,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentCurrencies: ['RUB', 'KZT'],
            paymentImg: 'VISA_MK',
            depositForm: 'general',
        },
        [Types.PAYMENT_TYPE_VISA_TURKEY]: {
            id: Types.PAYMENT_TYPE_VISA_TURKEY,
            name: 'Visa',
            paymentSystem: Types.PAYMENT_SYSTEM_VISA_TURKEY,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentCurrencies: ['USD'],
            paymentImg: 'VISA_MK',
            depositForm: 'general',
        },
        [Types.PAYMENT_TYPE_DEPOSIT]: {
            id: Types.PAYMENT_TYPE_DEPOSIT,
            name: 'Bank',
            paymentSystem: Types.PAYMENT_SYSTEM_DEPOSIT,
            paymentMethod: Types.PAYMENT_METHOD_EWallet,
            paymentImg: 'VISA_MK',
            depositForm: 'general',
            depositFormProps: {
                hideAmount: true,
            }
        },
        [Types.PAYMENT_TYPE_RQUEEN]: {
            id: Types.PAYMENT_TYPE_RQUEEN,
            name: 'Rqueen',
            paymentSystem: Types.PAYMENT_SYSTEM_RQUEEN,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentCurrencies: ['USDT', 'USD', 'RUB', 'KZT'],
            paymentImg: 'VISA_MK',
            depositForm: 'general',
        },
        [Types.PAYMENT_TYPE_TAP_BANK]: {
            id: Types.PAYMENT_TYPE_TAP_BANK,
            name: 'TapBank',
            paymentSystem: Types.PAYMENT_SYSTEM_TAP_BANK,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentCurrencies: ['RUB', 'KZT', 'AZN', 'TRY', 'UZS'],
            paymentImg: 'VISA_MK',
            depositForm: 'general',
        },
        [Types.PAYMENT_TYPE_PLAT_CORE]: {
            id: Types.PAYMENT_TYPE_PLAT_CORE,
            name: 'VISA',
            paymentSystem: Types.PAYMENT_SYSTEM_PLAT_CORE,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentCurrencies: ['TRY'],
            paymentImg: 'VISA_MK',
            depositForm: 'general',
            showPredefined: false
        },
        [Types.PAYMENT_TYPE_PAYTURCA]: {
            id: Types.PAYMENT_TYPE_PAYTURCA,
            name: 'MASTERCARD',
            paymentSystem: Types.PAYMENT_SYSTEM_PAYTURCA,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentCurrencies: ['TRY'],
            paymentImg: 'VISA_MK',
            depositForm: 'general',
            showPredefined: false
        },
        [Types.PAYMENT_TYPE_PAYMATRIX]: {
            id: Types.PAYMENT_TYPE_PAYMATRIX,
            name: 'Visa/Mastercard',
            paymentSystem: Types.PAYMENT_SYSTEM_PAYMATRIX,
            paymentMethod: Types.PAYMENT_METHOD_VISA,
            paymentCurrencies: ['TRY'],
            paymentImg: 'VISA_MK',
            depositForm: 'payMatrix',
            showPredefined: false,
            banks: [
                'Ziraat',
                'Garanti',
                'QNB',
            ]
        },
    },

    // Currencies precision configuration;
    currenciesPrecision: {
        'USD': 2,
        'EUR': 2,
        'BTC': 6,
        'BCH': 6,
        'ETH': 6,
        'LTC': 6,
        'USDT': 4,
        'XRP': 6,
        'DASH': 6,
        'DEFAULT': 2
    },

    valuePrecision: {
        default: 2,
        swap: 2,
        comission:2
    },

    getPaymentCurrencyPrecision: function (currency) {
        return this.currenciesPrecision[currency] ? this.currenciesPrecision[currency] : this.currenciesPrecision['DEFAULT'];
    },

    filterAmount: function (currency, amount) {
        amount = Number(amount);
        return amount.toFixed(this.getPaymentCurrencyPrecision(currency));
    },

    cardSystemIcon: {
        [Types.CARD_PAYMENT_SYSTEM_VISA]: 'VISA2',
        [Types.CARD_PAYMENT_SYSTEM_MASTER_CARD]: 'MASTERCARD',
    },

    // show languages
    languages : [
    Types.LANGUAGE_EN,
    Types.LANGUAGE_RU
    ],

    deposit: {
        minTotalDeposit: 0, // минимальная сумма пополнения для возможности торговли, для включения указать минимальный баланс в $ (не 0)
        useAccountCurrencyPm: true, // payment method depence on account currency
        autoChangeAccount: false, // account change when changing payment method
        autoReplaceDemoAccount: true, // auto change Demo account to Real with same currency
        defaultDepositAmount: {
            default: 1000,
            BTC: 0.01,
            ETH: 0.01,
        },
        exchangeCurrencies: [],
        paymentCurrenciesConfig: {
            'default': {
                id: 'default',
                name: 'default',
                symbol: '',
                defaultDepositValues: undefined,
                validation: {
                    minValue: 10.00,
                    maxValue: 100000.00,
                    minLength: 2,
                    maxLength: 9
                }
            },
            'USD': {
                id: 'USD',
                name: 'USD',
                symbol: "&#36",
                defaultDepositValues: undefined,
                validation: {
                    minValue: 10.00,
                    maxValue: 100000.00,
                    minLength: 2,
                    maxLength: 9
                }
            },
            'EUR': {
                id: 'EUR',
                name: 'EUR',
                symbol: "&euro;",
                defaultDepositValues: undefined,
            },
            'BTC': {
                id: 'BTC',
                name: 'BTC',
                symbol: "&#8383;",
                defaultDepositValues: undefined,
            },
            'ETH': {
                id: 'ETH',
                name: 'ETH',
                symbol: "&#926;",
                defaultDepositValues: undefined,
            },
        },
        // currency validation config by paymentMethod
        currencyValidation: {
            default: {
                default: {
                    minValue: 10.00,
                    maxValue: 100000.00,
                    minLength: 2,
                    maxLength: 9
                },
                BTC: {
                    minValue: 0.01,
                    maxValue: 30.00,
                    minLength: 1,
                    maxLength: 8
                },
                ETH: {
                    minValue: 0.01,
                    maxValue: 30.00,
                    minLength: 1,
                    maxLength: 8
                },
            },
            [Types.PAYMENT_TYPE_EASYPORTAL]: {
                USD: {
                    minValue: 140,
                    maxValue: 100000.00,
                    minLength: 2,
                    maxLength: 9
                },
                EUR: {
                    minValue: 110,
                    maxValue: 100000.00,
                    minLength: 2,
                    maxLength: 9
                },
            },
            [Types.PAYMENT_TYPE_WELLCOINEX]: {
                RUB: {
                    minValue: 100,
                    maxValue: Infinity,
                }
            },
            [Types.PAYMENT_TYPE_PAYPORT]: {
                RUB: {
                    minValue: 5000,
                    maxValue: Infinity,
                }
            },
            [Types.PAYMENT_TYPE_PLAT_CORE]: {
                TRY: {
                    minValue: 150,
                    maxValue: 140000,
                }
            },
            [Types.PAYMENT_TYPE_PAYMATRIX]: {
                TRY: {
                    minValue: 300.00,
                    maxValue: 300000.00,
                    minLength: 3,
                    maxLength: 6
                }
            },
        },

        validateAmount: {
            default: true,
            [Types.PAYMENT_TYPE_EASYPORTAL]: false,
        },
        validateExchangeAmount: {
            default: false,
            [Types.PAYMENT_TYPE_EASYPORTAL]: true,
        },
        currencyPaymentMethods: {},
        availablePaymentMethods: [],
        paymentMethodCustomName: {},
        predefinedAmounts: {
            default: {
                default: ['500', '1000', '2000', '5000'],
                USD: ['500', '1000', '2000', '5000'],
                EUR: ['500', '1000', '2000', '5000'],
                BTC: ['0.01', '0.1', '1'],
                ETC: ['0.1', '1', '5'],
            },
        },

        isValidateAmount: function (paymentMethodId) {
            return this.getParamValue(paymentMethodId, this.validateAmount);
        },

        isValidateExchangeAmount: function (paymentMethodId) {
            return this.getParamValue(paymentMethodId, this.validateExchangeAmount);
        },

        getCurrencyValidation: function (paymentMethodId, currency) {
            const pmCurrencyValidation = this.getParamValue(paymentMethodId, this.currencyValidation);
            return this.getParamValue(currency, pmCurrencyValidation);
        },

        getParamValue: function (param, config) {
            if (config[param] !== undefined) {
                return config[param];
            }

            return config.default;
        },
    },

    depositViaCrypto: {
        showBuyBlock: false, // show buy crypto block
        buyPaymentMethods: {},
        howToBuyLink: {},
        faqLink: "",
        partners: [
            {
                name: 'binance.com',
                logo: 'binance.svg',
                url: 'https://binance.com',
            },
            {
                name: 'nl.bitstamp.net',
                logo: 'bitstamp.svg',
                url: 'https://nl.bitstamp.net',
            },
            {
                name: 'coinmama.com',
                logo: 'coinmama.svg',
                url: 'https://www.coinmama.com',
            },
            {
                name: 'Moniv',
                logo: 'moniv.svg',
                url: 'https://moniv.io',
            },
            {
                name: 'coinbase.com',
                logo: 'coinbase.svg',
                url: 'https://www.coinbase.com',
            },
            {
                name: 'garantex.org',
                logo: 'garantex.svg',
                url: 'https://garantex.org',
            },
        ],
    },

    depositViaMidexpay: {
        defaultFiatAmount: 100,
        fiatCurrency: ['USD'],
        paymentSteps: [
            {
                id: Types.DEPOSIT_STEP_FORM,
                fromAmount: 0,
            },
            {
                id: Types.DEPOSIT_STEP_REGISTRATION,
                fromAmount: 0,
            },
            {
                id: Types.DEPOSIT_STEP_VERIFY_PHONE,
                fromAmount: 0,
            },
            {
                id: Types.DEPOSIT_STEP_KYC,
                fromAmount: 0,
            },
            {
                id: Types.DEPOSIT_STEP_DEPOSIT,
                fromAmount: 0,
            }
        ],
    },

    depositViaMidexpayWidget: {
        widgetUrl: 'https://widget.midexpay.com/',
        background: ''
    },

    depositViaSimplex: {
        fiatCurrency: ['USD'],
    },

    depositViaWebmoney: {
        wallets: [
            {
                address: 'E860990162435'
            },
            {
                address: 'Z526151190124',
            },
            {
                address: 'R523275941129',
            }
        ],
    },

    depositDemoAccount: {
        // by currency
        depositAmount: {
            default: 10000,
        }
    },

    /**
     * Return available payment method ids for given currency and payment type
     * @param {string} currency
     * @param {string} type deposit/withdraw
     * @returns {array}
     */
    getCurrencyPaymentMethods: function (currency, type = 'deposit') {
        const { useAccountCurrencyPm, availablePaymentMethods, currencyPaymentMethods } = this[type];

        if (!useAccountCurrencyPm) {
            return availablePaymentMethods;
        }

        const currencyPms = currencyPaymentMethods[currency] || currencyPaymentMethods.default || [];

        return currencyPms.filter(pmId => availablePaymentMethods.indexOf(pmId) !== -1);
    },

    /**
     * Return min deposit amount for given currency
     * @param {string} currency
     * @returns {number}
     */
    getCurrencyMinDepositAmount: function (currency) {
        const paymentMethods = this.getCurrencyPaymentMethods(currency, 'deposit');
        let min = 0;

        for (let i = 0; i < paymentMethods.length; i++) {
            const isValidate = this.deposit.isValidateAmount(paymentMethods[i])
                || this.deposit.isValidateExchangeAmount(paymentMethods[i]);

            if (!isValidate) {
                continue;
            }

            const validationConfig = this.deposit.getCurrencyValidation(paymentMethods[i], currency);

            if (!(validationConfig && validationConfig.minValue)) {
                continue;
            }

            if (min === 0 || validationConfig.minValue < min) {
                min = validationConfig.minValue;
            }
        }

        return min;
    },

    withdraw: {
        useAccountCurrencyPm: true, // payment method depence on account currency
        autoChangeAccount: false, // account change when changing payment method
        autoReplaceDemoAccount: true, // auto change Demo account to Real with same currency
        allowWithdrawBonus: false, // разрешить вывод бонусов (кредитных средств)
        availablePaymentMethods: [],
        paymentMethodCustomName: {},
        currencyPaymentMethods: {},
        predefinedAmounts: {
            default: [500, 1000, 2000, 'All'],
            BTC: [0.01, 0.1, 1, 'All'],
            ETH: [0.1, 1, 5, 'All'],
        },
        convertToCurrencies: [],
        convertFromCurrencies: [],
        currencyConfig: {
            'default': {
                validation: {
                    minValue: 15.00,
                    maxValue: 100000.00,
                    minLength: 2,
                    maxLength: 9
                }
            },
            'USD': {
                validation: {
                    minValue: 15.00,
                    maxValue: 100000.00,
                    minLength: 2,
                    maxLength: 9
                }
            },
            'EUR': {
                validation: {
                    minValue: 15.00,
                    maxValue: 100000.00,
                    minLength: 2,
                    maxLength: 9
                }
            },
            'BTC': {
                validation: {
                    minValue: 0.01,
                    maxValue: 30.00,
                    minLength: 1,
                    maxLength: 8
                }
            },
            'ETH': {
                validation: {
                    minValue: 0.01,
                    maxValue: 30.00,
                    minLength: 1,
                    maxLength: 8
                }
            },
            'USDT': {
                validation: {
                    minValue: 0.01,
                    maxValue: 30.00,
                    minLength: 1,
                    maxLength: 8
                }
            },
        },
    },

    cryptoAddressesValidate: {
        default: {
            length: 10
        },
        BTC: {
            startWith: ['1', '3', 'bc1'],
            length: 27
        },
        BCH: {
            startWith: ['1', 'bitcoincash:q', 'q'],
        },
        ETH: {
            startWith: ['0x'],
            length: 40
        },
        EOS: {
            startWith: ['eos'],
            length: 12
        },
        ADA: {
            startWith: ['addr']
        },
        DASH: {
            startWith: ['X']
        },
        AAVE: {
            startWith: ['0x']
        },
        ATOM: {
            startWith: ['cosmos']
        },
        AVAX: {
            startWith: ['X-avax', 'P-avax', '0x', 'C-avax']
        },
        CHZ: {
            startWith: ['0x']
        },
        DOGE: {
            startWith: ['D']
        },
        ETC: {
            startWith: ['0x']
        },
        IOTA: {
            startWith: ['iota1q']
        },
        LINK: {
            startWith: ['0x']
        },
        LTC: {
            startWith: ['M', '3', 'L']
        },
        LUNA: {
            startWith: ['terra']
        },
        MKR: {
            startWith: ['0x']
        },
        DOT: {
            startWith: ['1']
        },
        TRX: {
            startWith: ['T']
        },
        USDT_ERC20: {
            startWith: ['0x']
        },
        USDT_TRC20: {
            startWith: ['T']
        },
        XLM: {
            startWith: ['G']
        },
        XRP: {
            startWith: ['r']
        },
        XTZ: {
            startWith: ['tz']
        },
    },

    site: {
        isChangeDesktopTheme: true,
        desktopTheme: Types.THEMES.dark,
        isChangeMobileTheme: true,
        mobileTheme: Types.THEMES.dark,
        themeColors: {
            [Types.THEMES.dark]: [Types.THEME_COLORS.dark],
            [Types.THEMES.light]: [Types.THEME_COLORS.light],
        }
    },

    selectSupportedTags: ["input", "textarea"],

    googleAnalytics: {
        trackingId: '',
        GTMEvent: 'click'
    },

    hotjar: {
        hjId: '',
        snippetVersion: '',
    },

    headerButtons : {
        welcomeText: 'email'
    },

    instrumentDetails: {
        leverageDisplayFormat: 1, // 1 - 1:5, 2 - 5x
        useValuesFromConfig: false, // use commission and swap values from config
        learnMoreLink: '',
        fieldsTitle: {
            Ticker: 'Instrument',
            Leverage: 'Leverage',
            Commission: 'Commission',
            SwapLong: 'Buy swap',
            SwapShort: 'Sell swap',
            MinTickerSize: 'Min trade size',
            MaxTickerSize: 'Max trade size',
            LotSize: 'Lot size',
            maxTradeNumbers: 'Max number of trades',
            contractLength: 'Contract Length',
            positionLimit: 'Position Limit',
        },
        showFields: [
            'Ticker',
            'Leverage',
            'Commission',
            'SwapLong',
            'SwapShort',
            'MinTickerSize',
            'MaxTickerSize',
            'LotSize',
            'maxTradeNumbers',
            'contractLength',
            'positionLimit',
        ],
        staticFields: {},
        commissionValues: {
            default: 0,
        },
        swapValues: {
            default: {
                long: 0,
                short: 0,
            },
        },
    },

    instrumentImage: {
        dir: 'images/instruments/',
    },

    DefaultInstrumetLeverage: 0, // this value used if leverage for instrument not found

    /**
     * Work's mode of order create/edit form:
     * ORDERFORM_CORRECT_SL_TP - correct SL and TP values
     * ORDERFORM_VALIDATE_SL_TP - block form submit for incorrect SL and TP values
     * ORDERFORM_NOOP - do nothing
     */
    orderFormMode: Types.ORDERFORM_CORRECT_SL_TP,

    // default ticker of simple exchange form
    simpleExchangeDefaultTicker: 'BTC/USD',

    orderEdit: {
        hideSLTPPoints: false, // hide SL and TP points input
        synсSLTPUnitChange: true, // synchronous change unit for SL and TP
        defaultTP: 200, // Default percent of order for Take Profit. 200% of defaultPercentBet. значение по умолчанию для Types.SLTP_UNIT_PRICE задается в поинтах
        defaultSL: 100, // Default percent of order for Stop Loss. 100% of defaultPercentBet значение по умолчанию для Types.SLTP_UNIT_PRICE задается в поинтах
        defaultTPType: Types.SLTP_UNIT_PERCENT,
        defaultSLType: Types.SLTP_UNIT_PERCENT,
        minTakeProfitPoints: {
            default: 5,
        },
        minStopLossPoints: {
            default: -5,
        },
        stopLostUnitList: [{
            id: AutoOrder.UNIT.PERCENT,
            icon: SvgIcon.I_PERCENTAGE,
            title: isVersion6Design ? 'Loss as a percentage' : 'Loss percentage',
            precision: 2,
        }, {
            id: AutoOrder.UNIT.MONEY,
            icon: SvgIcon.I_MONEY,
            title: 'Loss size',
            precision: 2,
        }, {
            id: AutoOrder.UNIT.PRICE,
            icon: SvgIcon.I_ASSET_PRICE,
            title: isVersion6Design ? 'At a price' : 'Asset price',
            precision: 5,
        }],
        takeProfitUnitList: [{
            id: AutoOrder.UNIT.PERCENT,
            icon: SvgIcon.I_PERCENTAGE,
            title: isVersion6Design ? 'Profit as a percentage' : 'Profit percentage',
            precision: 2,
        }, {
            id: AutoOrder.UNIT.MONEY,
            icon: SvgIcon.I_MONEY,
            title: 'Profit size',
            precision: 2,
        }, {
            id: AutoOrder.UNIT.PRICE,
            icon: SvgIcon.I_ASSET_PRICE,
            title: isVersion6Design ? 'At a price' : 'Asset price',
            precision: 5,
        }],
    },

    /**
     * Return minimum difference in points of Take Profit values from order price for a given instrument
     * @param symbolId {number}
     * @returns {number}
     */
    getMinTakeProfitPoints: function (symbolId) {
        return this.orderEdit.minTakeProfitPoints[symbolId] ?
            this.orderEdit.minTakeProfitPoints[symbolId] : this.orderEdit.minTakeProfitPoints['default'];
    },

    /**
     * Return minimum difference in points of Stop Loss values from order price for a given instrument
     * @param symbolId {number}
     * @returns {number}
     */
    getMinStopLossPoints: function (symbolId) {
        return this.orderEdit.minStopLossPoints[symbolId] ?
            this.orderEdit.minStopLossPoints[symbolId] : this.orderEdit.minStopLossPoints['default'];
    },

    platformTutorials: {
        videoListUrl: '',
        videoLinks: [],
    },

    tickerButton: {
        showSpread: false,
        spreadType: Types.SPREAD_IN_USD, // one of Types.SPREAD_IN_LOTS or Types.SPREAD_IN_USD
        spreadInUsdAddPrecision: 2, // number of significant digits after decimal point in usd spread
        markPricePoints: false, // mark points of price
        showTrendSign: true, // show sign of price diff
    },

    chartTradingView: { // TRADING_VIEW, STOCK_CHARTS, charts switcher
        active: false,
        jQuery: {
            name: '$', 
            url: 'lib/JQuery.js'
        },
        TradingView: {
            name: 'TradingView',
            url: 'lib/chart/ChartingLibrary.js',
        },
    }, 

    /**
     * Trading view charts settings
     */
    tradingViewChart: {
        defaultTimeFrame: 1, // Set TimeFrame default value to 1 min
        hideVolumeIndicator: false, // Disable initial creation of the volume indicator
    },

    // Базовые настройки графиков котировок
    stockChart: {
        defaultSelectedIndicators: [],
        /**
         *  Redefine default chart settings:
         *     showAsk: true
         *     showBid: true
         *     showVolume: true
         *     showGrid: true
         *     chartType: 'CANDLES'
         *     frame: '1',
         */
        chartSettings: {
            showPriceIndicator: true, // Showing beacon(indicator on end) on chart
            showCursor: true, // Show crosshair cursor,
            showPendingOrders: true, // Show orders
            showLevelsProfitLoss: true, //Display "Close at profit" and "Close at loss" levels
            chartType: 'AREA',
            frame: '15F',
            isAutoUpdateFrameByZoom: false,
            isMonochrome: false,
            magnetMode: Types.MAGNET_MODES.OFF
        },

        isEnableLowLiquidityMessage: false, // enable low liquidity session message
        isEnableClosedTradeSessionMessage: false, // enable closed trade session message
        historyRequestTimeout: 2000, // quotes request timeout
        maxTabCount: 7,
        minHistoryBarsToShow: 20, // минимальное колличество баров для вывода графика, либо сообщение об ошибке
        maxCachedBars: 120000, // максимальное кол-во кешируемых всех баров для графика
        maxCashedFramesNumber: Infinity, // максимальное кол-во кешируемых фреймов для графика
        noGapBars: false, // build bars from quote without gap

        tickMode: {
            LENGTH_TO_SHOW: isMobile ? 25 : 50, //  Количество стартовых точек на графике типа "Tick"
            lineSeries: {       // Настройка линий для графика типа "Tick"
                opacity: 0.1,
                strokeWidth: 2
            }
        },
        basicMode: {
            LENGTH_TO_SHOW: isMobile ? 35 : 70, // Количество стартовых точек на графиках "Solid", "Area",  "Candles", "Heikin Ashi"
            volumeHeight: 30, // Высота графика Volume
            mainChartFixedHeightInPercentFor1: 0.78, // Фиксированная высота основного графика в процентном соотношении к канве (при добавлении 1 идикаторов)
            mainChartFixedHeightInPercentFor2: 0.57, // Фиксированная высота основного графика в процентном соотношении к канве (при добавлении 2 идикаторов)
            startRightSpace: isMobile ? 10 : 5,
            minChartPoints: 20, // минимальное кол-во точек на графике
            maxChartPoints: 100, // максимальное кол-во точек на графике
        },
        timeFormat: "%Y-%m-%d %H:%M:%S",
        /*
        если showCurrencyLabel=true, то  originStart: 80,
        */
        chartToolsTopOffset: 60,
        originStart: 55, // Стартовая точка для подсказки (Tooltip) внутренних (internal) индикаторов
        originStep: 15, // Шаг для розделения посказок внутренних индикаторов
        margin: isMobile  ? {left: 5, right: 60, top: 10, bottom:28} : { left: 30, right: isVersion6DesktopTarget ? 96 : isCustom2DesktopTarget ? 117 : 60, top: 43, bottom: 0 }, // Внешние отступы графика
        padding: { top: 50, bottom: 10 }, // Внутренние отступы графика
        zoomBoxSize: 120,
        zoomBoxScale: 150, // zoom in percents: 200% for 2x zoom
        // Настройки заголовка графика
        label: {
            x: 75,
            y: 50,
            fontFamily: 'Open Sans',
            fontSize: isMobile ? 14 : 18,
            fontWeight: 900
        },
        // Настройки цветовой схемы
        colors: {
            lightGreen: isCustomDesigns ? 'var(--color--buy--default, #00CE7D)' : isVersion6MobileTarget ? '#1BD6A9' : isVersion6DesktopTarget ? '#22AB94' : "#3CB985",
            lightBlue: isVersion6Design ? "#5E91E8" : "#5390E5",
            lightRed: isCustomDesigns ? 'var(--color--sell--default, #FF563F)' : isVersion6Design ? '#F94E4E' : "#E3533F",
            lightGrey: isVersion6Design ? "#2C3E50" : "#4e525d"
        },
        monochromeColors: {
            wite: isVersion6Design ? "#2C3E50" : "#FFFFFF",
            lightGrey: isVersion6Design ? "#98B7D5" : "#6A7D8F"
        },
        // Настройки шкалы X
        xAxis: {
            axisAt: "bottom",
            orient: "bottom",
            stroke: isMobile ? "#1B2B35" : "#7E91A31A",
            strokeWidth: isCustomDesigns ? 0 : 1,
            ticks: isMobile ? 4 : 6,
            fontFamily: isVersion6Design ? 'Roboto' : isCustom2Design ? 'Ubuntu' : isCustomDesigns ? 'Exo 2' : 'Open Sans',
            fontSize: isMobile ? 8 : isCustomDesigns ? 11 : 12,
            className:"xAxisStyle"
        },
        extendsIndicatorsYAxis: {
            axisAt: "right",
            orient: "right",
            stroke: isMobile ? "#1B2B35" : "#7E91A31A",
            strokeWidth: 1,
            ticks: isMobile ? 0 : 4,
            fontFamily: 'Open Sans',
            fontSize: isMobile ? 8 : 13,
            tickPadding: isVersion6DesktopTarget ? 12 : isCustom2DesktopTarget ? 62 : 9,
            zoomEnabled: isDesktop,
            className:"yAxisStyle"
        },
        xAxisDark: {
            axisAt: "bottom",
            orient: "bottom",
            stroke: isMobile ? "#7E91A3" : "#7E91A31A",
            strokeWidth: 1,
            ticks: isMobile ? 4 : 6,
            fontFamily: 'Open Sans',
            fontSize: isMobile ? 8 : 12,
            tickStroke: "#ff0000",
        },
        // Настройки шкалы Y
        yAxis: {
            axisAt: "right",
            orient: "right",
            stroke: isVersion6DesktopTarget ? '#EBF0F5' : isMobile ? isVersion6MobileTarget ? "#ffffff" : "#1B2B35" : "#7E91A31A",
            strokeWidth: isCustomDesigns ? 0 : 1,
            ticks: isVersion6DesktopTarget ? 13 : isMobile ? 3 : 6,
            fontFamily: isVersion6Design ? 'Roboto' : isCustom2Design ? 'Ubuntu' : isCustomDesigns ? 'Exo 2' : 'Open Sans',
            fontSize: isMobile ? 8 : isCustomDesigns ? 11 : 13,
            tickPadding: isVersion6DesktopTarget ? 12 : isCustom2DesktopTarget ? 62 : 9,
            zoomEnabled: true,
            className:"yAxisStyle"
        },
        // Profit Scale config
        profitScale: {
            profitLabelPadding: 6,
            profitTickMargin: isVersion6Design ? 2.3 : 2,
            profitMainTickWidth: isVersion6Design ? 12 : 18,
            profitTickWidth: isVersion6Design ? 8 : isCustomDesigns ? 12 : 14,
            profitTickHeight: 2,
            profitTickRadius: isVersion6Design ? 2 : isCustomDesigns ? 3 : 1,
            profitTickColor: isVersion6Design ? "rgba(109, 143, 178, 0.32)" : isCustom2Design ? "#363636" : isCustomDesigns ? "#3B4859" : "#566877",
        },
        // Настройки сетки графика
        xGrid: {
            tickStrokeOpacity: 0.1,
            tickStroke: isCustom2Design ? "var(--color--chart--lines)" : "#000000"
        },
        xGridDark: {
            tickStrokeOpacity: isVersion6DesktopTarget ? 0 : isVersion6Design ? 0.32 : 0.1,
            tickStroke: isVersion6Design ? "#C2D6E9" : isCustom2Design ? "var(--color--chart--lines)" : isCustomDesigns ? "#fff" : isMobile ? isVersion6MobileTarget ? "rgba(194, 214, 233, 0.32)" : "#294354" : "#7E91A3",
            tickLabelFill: isCustom2Design ? "#757575" : isMobile ? isVersion6MobileTarget ? "#4a6784" : "#7E91A3": "#7E91A3"
        },
        yGrid: {
            tickStrokeOpacity: 0.1,
            tickStroke: isCustom2Design ? "var(--color--chart--lines)" : "#000000"
        },
        yGridDark: {
            tickStrokeOpacity: isVersion6DesktopTarget ? 1 : isVersion6MobileTarget ? 0.32 : 0.1,
            tickStroke: isVersion6DesktopTarget ? '#F8FAFC' : isVersion6MobileTarget ? "#C2D6E9" : isCustom2Design ? "var(--color--chart--lines)" : isCustomDesigns ? "#fff" : isMobile ? isVersion6MobileTarget ? "rgba(194, 214, 233, 0.32)" : "#294354" : "#7E91A3",
            tickLabelFill: isCustom2Design ? "#757575" : isMobile ? isVersion6MobileTarget ? "#4a6784" : "#7E91A3": "#7E91A3"
        },
        // Настройки для отображения координат шкалы Y
        mouseCoordinateY: {
            at: "right",
            orient: "right",
            dy: ".28em",
            dx: isVersion6MobileTarget ? -5 : isVersion6DesktopTarget ? 4 : isCustom2DesktopTarget ? 12 : 0,
            arrowWidth: 0,
            strokeWidth: 2,
            stroke: isVersion6DesktopTarget ? "#EBF0F5" : isVersion6MobileTarget ? "#d0dbe6" : "#4e525d",
            tickStrokeOpacity: 1,
            fill: isVersion6DesktopTarget ? "#EBF0F5" : isVersion6MobileTarget ? "#d0dbe6" : isCustom2Design ? 'var(--color--indicator)' : isCustomDesigns ? "rgba(39, 47, 68, 0.9)" : "rgba(17,32,41, 0.8)",
            textFill: isCustom2Design ? "var(--color--indicator--text)" : isVersion6DesktopTarget ? "#31475E" : isVersion6MobileTarget ? "#2C3E50" : "#fff",
            lineStroke: "#4e525d",
            fontFamily: isVersion6Design ? "Roboto" : isCustom2Design ? 'Ubuntu' : isCustomDesigns ? 'Exo 2' : 'Open Sans',
            fontSize: isVersion6Design ? 12 : isCustomDesigns ? 14 : isMobile ? 10 : 13,
            fontWeight: isVersion6Design ? 500 : 400,
            rectWidth: isVersion6DesktopTarget ? 102 : isCustom2DesktopTarget ? 98 : 66,
            textAnchor: 'start',
            rectHeight: isCustom2Design || isVersion6Design ? 20 : 25,
            className:"mouseCoordinate"
        },
        // Настройки для отображения координат шкалы X
        mouseCoordinateX: {
            at: "bottom",
            orient: "bottom",
            dy: isVersion6DesktopTarget ? "9px" : ".28em",
            strokeWidth: isVersion6DesktopTarget ? 0 : 2,
            stroke: isVersion6DesktopTarget ? "#EBF0F5" : isVersion6MobileTarget ? "#d0dbe6" : "#4e525d",
            tickStrokeOpacity: 1,
            fill: isVersion6DesktopTarget ? "#EBF0F5" : isVersion6MobileTarget ? "#d0dbe6" : isCustom2Design ? '#3a3a3a' : isCustomDesigns ? "#0C1224" : "rgba(17,32,41, 0.8)",
            textFill: isVersion6DesktopTarget ? "#31475E" : isVersion6MobileTarget ? "#2C3E50" : "#fff",
            lineStroke: "#4e525d",
            fontFamily: isVersion6Design ? "Roboto" : isCustom2Design ? 'Ubuntu' : isCustomDesigns ? 'Exo 2' : 'Open Sans',
            fontSize: isVersion6Design ? 12 : isCustomDesigns ? 14 : isMobile ? 10 : 13,
            rectWidth: isCustomDesigns ? 147 : 135,
            rectHeight: isVersion6DesktopTarget ? 20 : isCustomDesigns ? 24 : 25,
            rectRadius: isVersion6DesktopTarget ? 1 : isVersion6MobileTarget ? 2 : 3,
            rectDy: isVersion6DesktopTarget ? 5 : 0,
            fontWeight: isVersion6Design ? 500 : 400,
            className:"mouseCoordinate"
        },
        // Настройки указателей текущей цены
        priceIndicator: {
            yAxisPad: 0,
            rectWidth: 5,
            rectHeight: 5,
            orient: 'top',
            at: 'right',
            dx: 0,
            arrowWidth: 0,
            opacity: 1,
            lineOpacity: 1,
            fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
            fontSize: isMobile ? 10 : 12,
            strokeOpacity: 1,
            strokeWidth: 1,
            strokeDasharray: 'Dash',
            fill: "#FFFFFF"
        },
        priceCoordinate: {
            yAxisPad: 0,
            rectWidth: isVersion6MobileTarget ? 74 : isVersion6DesktopTarget ? 99 : isCustom2MobileTarget ? 60 : isCustom2DesktopTarget ? 85 : isMobile ? 58 : isCustomDesigns ? 64 : 70,
            rectHeight: isVersion6DesktopTarget ? 19 : isCustom2Design ? 20 : isMobile ? 12 : 24,
            orient: !isVersion6Design && 'right',
            textAlign: isVersion6DesktopTarget ? 'left' : 'right',
            at: 'right',
            dx: isVersion6DesktopTarget ? 7 : isVersion6MobileTarget ? -24 : isCustom2DesktopTarget ? 12 : -10,
            arrowWidth: isMobile ? 7 : 13,
            opacity: 1,
            lineOpacity: 1,
            fontFamily: isVersion6Design ? "Roboto" : 'Helvetica Neue, Helvetica, Arial, sans-serif',
            fontWeight: (isCustom2Design || isVersion6Design) ? 500 : 400,
            fontSize: isCustom2Design ? (isMobile ? 7 : 11) : isMobile ? 7 : isVersion6DesktopTarget ? 12 : 14,
            strokeOpacity: 1,
            strokeWidth: 1,
            rectRadius: isCustom2Design ? 1 : isVersion6MobileTarget ? 3.5 : isVersion6DesktopTarget ? 2 : 3,
            strokeDasharray: 'Dash'
        },
        priceCoordinateAsk: {
            fill: isCustom2Design ? "var(--color--indicator)" : isVersion6DesktopTarget ? "#22AB94" : isVersion6MobileTarget ? "#F94E4E" : "#FFFFFF",
            lineStroke: isVersion6DesktopTarget ? "#22AB94" : isVersion6MobileTarget ? "#2C3E50" : "#FFFFFF",
            textFill: isVersion6DesktopTarget ? "#FFFFFF" : isVersion6MobileTarget ? "#2C3E50" : "#32404A",
            stroke: isVersion6DesktopTarget ? "#22AB94" : isVersion6MobileTarget ? "#2C3E50" : "#FFFFF",
            lineStrokeDasharray:"ShortDash"
        },
        priseCoordinateBid: {
            fill: isCustom2Design ? "var(--color--indicator)" : isVersion6DesktopTarget ? "#22AB94" : isVersion6MobileTarget ? "#1BD6A9" : "#FFFFFF",
            lineStroke: isVersion6DesktopTarget ? "#22AB94" : isVersion6MobileTarget ? "#2C3E50" : "#FFFFFF",
            textFill: isVersion6DesktopTarget ? "#FFFFFF" : isVersion6MobileTarget ? "#2C3E50" : "#32404A",
            stroke: isVersion6DesktopTarget ? "#22AB94" : isVersion6MobileTarget ? "#2C3E50" : "#FFFFFF",
            lineStrokeDasharray:"ShortDash"
        },

        priceCoordinatePositionBuy: {
            yAxisPad: 0,
            rectWidth: 50,
            rectHeight: 30,
            rectRadius: 4,
            orient: 'left',
            at: 'left',
            dx: 40,
            arrowWidth: 0,
            fill: '#00CE7D',
            opacity: 0.7,
            lineOpacity: 1,
            lineStroke: '#00CE7D',
            fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
            fontSize: isMobile ? 10 : 12,
            textFill: '#FFFFFF',
            strokeOpacity: 1,
            strokeWidth: 1,
            strokeDasharray: 'Dash'
        },
        priceCoordinatePositionSell: {
            yAxisPad: 0,
            rectWidth: 50,
            rectHeight: 30,
            rectRadius: 4,
            orient: 'left',
            at: 'left',
            dx: 40,
            arrowWidth: 0,
            fill: '#FF563F',
            opacity: 0.7,
            lineOpacity: 1,
            lineStroke: '#FF563F',
            fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
            fontSize: isMobile ? 10 : 12,
            textFill: '#FFFFFF',
            strokeOpacity: 1,
            strokeWidth: 1,
            strokeDasharray: 'Dash'
        },
        priceCoordinatePositionTP: {
            yAxisPad: 0,
            rectWidth: 180,
            rectHeight: 30,
            orient: 'left',
            at: 'left',
            dx: 170,
            arrowWidth: 0,
            fill: '#1A212A',
            opacity: 0.7,
            lineOpacity: 1,
            lineStroke: '#00CE7D',
            fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
            fontSize: isMobile ? 10 : 12,
            textFill: '#FFFFFF',
            stroke: '#00CE7D',
            strokeOpacity: 1,
            strokeWidth: 1,
            strokeDasharray: 'Dot'
        },
        priceCoordinatePositionSL: {
            yAxisPad: 0,
            rectWidth: 180,
            rectHeight: 30,
            orient: 'left',
            at: 'left',
            dx: 170,
            arrowWidth: 0,
            fill: '#1A212A',
            opacity: 0.7,
            lineOpacity: 1,
            lineStroke: '#FF563F',
            fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
            fontSize: isMobile ? 10 : 12,
            textFill: '#FFFFFF',
            stroke: '#FF563F',
            strokeOpacity: 1,
            strokeWidth: 1,
            strokeDasharray: 'Dot'
        },
        orderCoordinatePositionBuy: {
            yAxisPad: 0,
            rectWidth: 50,
            rectHeight: 30,
            rectRadius: 4,
            orient: 'left',
            at: 'left',
            dx: 40,
            arrowWidth: 0,
            fill: '#7E91A3',
            opacity: 0.7,
            lineOpacity: 1,
            lineStroke: '#7E91A3',
            fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
            fontSize: isMobile ? 10 : 12,
            textFill: '#FFFFFF',
            stroke: '#00CE7D',
            strokeOpacity: 1,
            strokeWidth: 1,
            strokeDasharray: 'Dash'
        },
        orderCoordinatePositionSell: {
            yAxisPad: 0,
            rectWidth: 50,
            rectHeight: 30,
            rectRadius: 4,
            orient: 'left',
            at: 'left',
            dx: 40,
            arrowWidth: 0,
            fill: '#7E91A3',
            opacity: 0.7,
            lineOpacity: 1,
            lineStroke: '#7E91A3',
            fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
            fontSize: isMobile ? 10 : 12,
            textFill: '#FFFFFF',
            stroke: '#FF563F',
            strokeOpacity: 1,
            strokeWidth: 1,
            strokeDasharray: 'Dash'
        },
        orderCoordinatePositionTP: {
            yAxisPad: 0,
            rectWidth: 55,
            rectHeight: 30,
            rectRadius: 4,
            orient: 'left',
            at: 'left',
            dx: 40,
            arrowWidth: 0,
            fill: '#7E91A3',
            opacity: 0.7,
            lineOpacity: 1,
            lineStroke: '#7E91A3',
            fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
            fontSize: isMobile ? 10 : 12,
            textFill: '#FFFFFF',
            stroke: '#00CE7D',
            strokeOpacity: 1,
            strokeWidth: 1,
            strokeDasharray: 'Dot'
        },
        orderCoordinatePositionSL: {
            yAxisPad: 0,
            rectWidth: 55,
            rectHeight: 30,
            rectRadius: 4,
            orient: 'left',
            at: 'left',
            dx: 40,
            arrowWidth: 0,
            fill: '#7E91A3',
            opacity: 0.7,
            lineOpacity: 1,
            lineStroke: '#7E91A3',
            fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
            fontSize: isMobile ? 10 : 12,
            textFill: '#FFFFFF',
            stroke: '#FF563F',
            strokeOpacity: 1,
            strokeWidth: 1,
            strokeDasharray: 'Dot'
        },
        chartPendingOrderControls: {
            pendingPlusButton: {
                width: 20,
                height: 20,
                color: isVersion6Design ? "#2C3E50" : isCustom2Design ? '#757575' : isCustomDesigns ? '#7E91A3' : '#92A5B7',
                hoverColor: isVersion6Design ? "#2C3E50" : "#FFFFFF" ,
            },
            pendingButton: {
                height: 40,
                offset: 17,
                arrowPositionXOffset: 5,
                arrowPositionYOffset: isCustomDesigns ? 10 : 9,
                buttonRectRadius: 3,
                strokeWidth: isCustom2Design ? 2 : 1,
                buyButtonColor: isCustom2Design ? '#1E1E20' : isCustomDesigns ? '#2C354CCC' : '#134942',
                buyButtonStrokeColor: isCustom2Design ? '#0B934B' : isCustomDesigns ? '#0C825F' : '#1acd7d',
                buyButtonHoverColor: isCustom2Design ? '#0B934B' : isCustomDesigns ? '#0C825F' : '#117854',
                sellButtonColor: isCustom2Design ? '#1E1E20' : isCustomDesigns ? '#2C354CCC' : '#4a3031',
                sellButtonStrokeColor: isCustom2Design ? '#FF563F' : isCustomDesigns ? '#FF563F' : '#fb5741',
                sellButtonHoverColor: isCustom2Design ? '#EF592F' : isCustomDesigns ? '#FF563F' : '#d44e3f',
                textLabelPadding: 8,
            }
        },
        crosshair: {
            lineStyles: {
                stroke: '#7e91A3',
                strokeDasharray: 'ShortDot',
            },
            labelStyles: {
                fill: '#fff',
                bgColor: '#1b2b35',
                bgOpacity: 0.8,
                radius: 3 ,
                fontSize: 14,
                fontFamily: 'Open Sans',
            },
            arrowStyles: {
                width: 5,
                height: 7,
                color: '#7e91A3',
            },
        },

        showCurrencyLabel: false,//показывать или нет на графике StockChart label с выбранной валютой. Если показываем, то нужно поправить настройки ниже!!!
        /*если showCurrencyLabel=true,
            ohlcTooltip origin: [5, 30],
            movingAverageTooltip: origin: [10, 40],
            mamaTooltip: origin: [10, 40],
            groupTooltip: origin: [10, 35],
            singleValueTooltip: origin: [0, 30],
         */
        // Настройка подсказки для детальной информации по текущей координате
        ohlcTooltip: {
            origin: [5, 5],
            fontSize: isMobile ? 10 : 12,
            fontWeight: 900,
            labelFill: "#4e525d"
        },
        // Настройка подсказки для индикатора группы Moving Average
        movingAverageTooltip: {
            origin: [10, 15],
            width: 85
        },
        mamaTooltip: {
            origin: [10, 15],
            width: 170
        },
        // Настройка общей подсказки с несколькими значениями
        groupTooltip: {
            width: 85,
            origin: [10, 10],
            fontSize: isMobile ? 10 : 12,
            fontWeight: 900,
            labelFill: "#4e525d"
        },
        // Настройка общей подсказки с одним значениями
        singleValueTooltip: {
            labelFill: "#4e525d",
            origin: [0, 27]
        },
        // Базовые настройки для графика типа "Bar"
        barSeries: {
            widthRatio: 1,
            opacity: 0.6
        },
        // Базовые настройки для графика типа "Line"("Solid")
        lineSeries: {
            strokeWidth: 2,
            stroke: "#FF971D"
        },
        // Базовые настройки для графика типа "Area"
        areaSeries: {
            strokeWidth: 2,
            stroke: "#FF971D",
            opacity: 0.1
        },
        // Базовые настройки для графика типа "Candles"
        candleSeries: {
            opacity: 1,
            widthRatio: isVersion6DesktopTarget ? 0.5 : 0.8,
        },
        // Настройки градиента для графика типа "Tick"
        tickGradient: [
            { stop: 0, color: "rgba(255, 86, 63, 0.2)", opacity: 0.1 },
            { stop: 0.5, color: "rgba(60, 185, 133, 0.15)", opacity: 0.1 },
            { stop: 1, color: "rgba(60, 185, 133, 0.15)", opacity: 0.1 }
        ],
        // Настройки градиента для графика типа "Area"
        areaGradient: [
            { stop: 0, color: "rgba(255, 151, 29, 0.05)", opacity: 0.1 },
            { stop: 1, color: "rgba(255, 151, 29, 0.2)", opacity: 0.1 }
        ],
        // Настройка линиий указателя мыши
        crossHairCursor: {
            opacity: 0.5,
            strokeDasharray:"none",
        },
        crossHairCursorDark: {
            opacity: isCustomDesigns ? 1 : isMobile ? 1 : 0.5,
            stroke: isCustom2Design ? "#757575" : (isCustomDesigns && isMobile) ? "#FFFFFF" :"#7E91A3",
            strokeDasharray: isCustomDesigns ? "Dash" : "Solid",
        },
        // Настройка подсказок для координат Y
        edgeIndicator: {
            itemType: "last",
            orient: "right",
            edgeAt: "right",
            fontSize: isCustom2Design ? (isMobile ? 8 : 11) : isMobile && !isVersion6MobileTarget ? 8 : 12,
            dx: isVersion6DesktopTarget ? 4 : isCustom2DesktopTarget ? 12 : -10,
            dy: '.3em',
            arrowType: isCustomDesigns || isVersion6Design ? 'rect' : 'arrow',
            arrowWidth: isCustom2Design ? 2 : isCustomDesigns ? (isMobile ? 4 : 7) : isVersion6Design ? 3 : (isMobile ? 6 : 13),
            textAnchor: isVersion6DesktopTarget ? "start" : isCustom2DesktopTarget ? "end" : undefined,
            fixedTextBackground: isVersion6Design ? "#EBF0F5" : isCustom2Design ? "#404040" : "#FFFFFF",
            textFill: isVersion6Design ? '#31475E' : isCustom2Design ? "var(--color--indicator--text)" : "#1B2B35",
            fontWeight: isVersion6Design ? 500 : 400,
            fontFamily: isVersion6Design ? "Roboto" : 'Helvetica Neue, Helvetica, Arial, sans-serif',
            rectWidth: isCustom2MobileTarget ? 70 : isCustom2DesktopTarget ? 95 : isVersion6DesktopTarget ? 98 : isMobile ? isVersion6MobileTarget ? 56 : 60 : 60,
            drawDot: isVersion6Design || isCustom2DesktopTarget,
            rectHeight: isMobile ? isVersion6MobileTarget ? 15 : 12 : isVersion6DesktopTarget ? 19 : isCustom2Design ? 19 : 20,
            rectRadius: isCustom2Design ? 2 : isVersion6MobileTarget ? 4 : isVersion6DesktopTarget ? 1 : 3,
            lineStroke: isVersion6Design || isCustom2DesktopTarget ? null : "#FFFFFF",
            lineOpacity: 1,
            stroke: isVersion6Design ? "#EBF0F5" : isCustom2Design ? "var(--color--indicator--stroke)" : "#FFFFFF",
            strokeWidth: 1,
            strokeOpacity: 1,
        },
        // Высота графика и его панели управления
        mobileWrapperHeight : 246,
        //Тонировка спреда
        spreadFillColor:"rgba(0, 0, 0, 0.2)",
        //Цвета градинетной заливки
        gradientLineColor:"#FFFFFF",
        gradientFillBuy:{
            tp:{color1:"rgba(0, 206, 125, 0)", color2:"rgba(0, 206, 125, 0.1)"},
            sl:{ color1:"rgba(255, 86, 63, 0.1)",  color2:"rgba(255, 86, 63, 0)"}
        },
        gradientFillSell:{
            tp:{color1:"rgba(0, 206, 125, 0.1)", color2:"rgba(0, 206, 125, 0)"},
            sl:{color1:"rgba(255, 86, 63, 0)", color2:"rgba(255, 86, 63, 0.1)"}
        },
        sltpOutsideBuyColor: "rgba(0, 206, 125, 0.1)",
        sltpOutsideSellColor: "rgba(255, 86, 63, 0.1)",
        //Прозрачность элементов графика
        activeOpacity:1,
        unActiveOpacity: 0.1
    },
    groupsObjectsGroups: { // For draw objects modal window
        "FAVORITES": {
            label: "Favorites",
            icon: SvgIcon.I_STAR,
            showCounter: true,
            elements: [], // Any of TYPES.CHART_DRAW_LINES
        },
        "ADDED" : {
            label: "Added",
            icon: SvgIcon.I_ADDED,
            showCounter: true,
            elements: [],
        },
        "POPULAR": {
            label: "Popular",
            icon: SvgIcon.I_POPULAR,
            showCounter: false,
            elements: ["XLINE", "HORIZONTALLINE", "RAY", "VERTICALLINE", "LINE", "FIBONACCI_CHANNEL", "FIBONACCI_TIME_ZONE", "GANN_FAN", "TEXT_MARK"], // Any of TYPES.CHART_DRAW_LINES
        },
        "TREND_LINES": {
            label: "Trend lines",
            icon: SvgIcon.I_LINES,
            showCounter: false,
            elements: ["CURVE_LINE", "XLINE", "HORIZONTALLINE", "HORIZONTALRAY", "RAY", "VERTICALLINE", "LINE", "TRENDANGEL", "ARROWLINE", "EQUIDISTANT_CHANNEL", "STANDARD_DEVIATION_CHANNEL"], // Any of TYPES.CHART_DRAW_LINES
        },
        "GANNA_AND_FIBONACCI_TOOLS": {
            label: "Gann and Fibonacci tools",
            icon: SvgIcon.I_FIBONACCI_FIBONACCI_CHANNELS,
            showCounter: false,
            elements: ["FIBONACCI_CHANNEL", "FIBONACCI_RETRACEMENT", "FIBONACCI_TIME_ZONE", "GANN_FAN", "PITCHFORK", "TRENDBASE_FIBONACCI_EXTENSION"], // Any of TYPES.CHART_DRAW_LINES
        },
        "GEOMETRIC_FIGURES": {
            label: "Geometric patterns",
            icon: SvgIcon.I_SHAPES,
            showCounter: false,
            elements: ["INTERACTIVE_BRUSH", "RECTANGLE", "ROTATED_RECTANGLE", "ROTATED_TRIANGLE", "ROTATED_ELLIPSE"], // Any of TYPES.CHART_DRAW_LINES
        },
        "COMMENTING_TOOLS": {
            label: "Commenting tools",
            icon: SvgIcon.I_NOTES_TEXT,
            showCounter: false,
            elements: ["ARROW_MARK_DOWN", "ARROW_MARK_UP", "ARROW_MARK_FLAG", "TEXT_MARK"], // Any of TYPES.CHART_DRAW_LINES
        },
    },
    schemeFieldsOfDrawingObject: {
        TEXT_MARK: {
            text: {
                show: true,
                type: 'textarea'
            },
            background: {
                show: true,
            },
            fontSize:
                [
                    {name: '9', value: '9'},
                    {name: '12', value: '12'},
                    {name: '14', value: '14'},
                    {name: '18', value: '18'},
                    {name: '24', value: '24'},
                    {name: '36', value: '36'},
                ]
        },
        DEFAULT: {
            text: {
                show: true,
                type: 'text',
            },
            background: {
                show: false,
            }
        }
     },

    getSchemeFieldsOfDrawingObject(name) {
        return this.schemeFieldsOfDrawingObject[name] || this.schemeFieldsOfDrawingObject.DEFAULT;
    },

    fastEditOfDrawObject: {
        [Types.DRAWING_TRENDS.TRENDLINE]: {
            [Types.OBJECT_EDIT_STROKE]: {
                path: 'stroke',
                type: 'stroke',
            },
            [Types.OBJECT_EDIT_LINE_WIDTH]: {
                path: 'strokeWidth',
                type: 'width',
            },
            [Types.OBJECT_EDIT_LINE_TYPE]: {
                path: 'strokeDasharray',
                type: 'line',
            },
        },
        [Types.DRAWING_TRENDS.SIMPLELINE]: {
            [Types.OBJECT_EDIT_STROKE]: {
                path: 'stroke',
                type: 'stroke',
            },
            [Types.OBJECT_EDIT_LINE_WIDTH]: {
                path: 'strokeWidth',
                type: 'width',
            },
            [Types.OBJECT_EDIT_LINE_TYPE]: {
                path: 'strokeDasharray',
                type: 'line',
            },
        },
        [Types.DRAWING_TRENDS.TRENDANGEL]: {
            [Types.OBJECT_EDIT_STROKE]: {
                path: 'stroke',
                type: 'stroke',
            },
            [Types.OBJECT_EDIT_LINE_WIDTH]: {
                path: 'strokeWidth',
                type: 'width',
            },
            [Types.OBJECT_EDIT_LINE_TYPE]: {
                path: 'strokeDasharray',
                type: 'line',
            },
        },
        [Types.DRAWING_TRENDS.EQUIDISTANT_CHANNEL]: {
            [Types.OBJECT_EDIT_STROKE]: {
                path: 'stroke',
                type: 'stroke',
            },
            [Types.OBJECT_EDIT_FILL]: {
                path: 'fill',
                type: 'fill',
            },
            [Types.OBJECT_EDIT_LINE_WIDTH]: {
                path: 'strokeWidth',
                type: 'width',
            },
            [Types.OBJECT_EDIT_LINE_TYPE]: {
                path: 'strokeDasharray',
                type: 'line',
            },
            [Types.OBJECT_EDIT_PROPS]: {
                type: 'settings',
            },
        },
        [Types.DRAWING_TRENDS.STANDARD_DEVIATION_CHANNEL]: {
            [Types.OBJECT_EDIT_STROKE]: {
                path: 'stroke_base',
                type: 'stroke',
            },
            [Types.OBJECT_EDIT_LINE_WIDTH]: {
                path: 'strokeWidth_base',
                type: 'width',
            },
            [Types.OBJECT_EDIT_LINE_TYPE]: {
                path: 'strokeDasharray_base',
                type: 'line',
            },
            [Types.OBJECT_EDIT_PROPS]: {
                type: 'settings',
            },
        },
        [Types.DRAWING_TRENDS.PITCHFORK]: {
            [Types.OBJECT_EDIT_STROKE]: {
                path: 'stroke',
                type: 'stroke',
            },
            [Types.OBJECT_EDIT_LINE_WIDTH]: {
                path: 'strokeWidth',
                type: 'width',
            },
            [Types.OBJECT_EDIT_LINE_TYPE]: {
                path: 'strokeDasharray',
                type: 'line',
            },
            [Types.OBJECT_EDIT_PROPS]: {
                type: 'settings',
            },
        },
        [Types.DRAWING_TRENDS.FIBONACCI_TIME_ZONE]: {
            [Types.OBJECT_EDIT_STROKE]: {
                path: 'stroke',
                type: 'stroke',
            },
            [Types.OBJECT_EDIT_LINE_WIDTH]: {
                path: 'strokeWidth',
                type: 'width',
            },
            [Types.OBJECT_EDIT_LINE_TYPE]: {
                path: 'strokeDasharray',
                type: 'line',
            },
            [Types.OBJECT_EDIT_PROPS]: {
                type: 'settings',
            },
        },
        [Types.DRAWING_TRENDS.FIBONACCI_RETRACEMENT]: {
            [Types.OBJECT_EDIT_STROKE]: {
                path: 'stroke',
                type: 'stroke',
            },
            [Types.OBJECT_EDIT_LINE_WIDTH]: {
                path: 'strokeWidth',
                type: 'width',
            },
            [Types.OBJECT_EDIT_LINE_TYPE]: {
                path: 'strokeDasharray',
                type: 'line',
            },
            [Types.OBJECT_EDIT_PROPS]: {
                type: 'settings',
            },
        },
        [Types.DRAWING_TRENDS.FIBONACCI_CHANNEL]: {
            [Types.OBJECT_EDIT_LINE_WIDTH]: {
                path: 'strokeWidth',
                type: 'width',
            },
            [Types.OBJECT_EDIT_LINE_TYPE]: {
                path: 'strokeDasharray',
                type: 'line',
            },
            [Types.OBJECT_EDIT_PROPS]: {
                type: 'settings',
            },
        },
        [Types.DRAWING_TRENDS.TRENDBASE_FIBONACCI_EXTENSION]: {
            [Types.OBJECT_EDIT_STROKE]: {
                path: 'strokeTrend',
                type: 'stroke',
            },
            [Types.OBJECT_EDIT_LINE_WIDTH]: {
                path: 'strokeTrendWidth',
                type: 'width',
            },
            [Types.OBJECT_EDIT_LINE_TYPE]: {
                path: 'strokeTrendDasharray',
                type: 'line',
            },
            [Types.OBJECT_EDIT_PROPS]: {
                type: 'settings',
            },
        },
        [Types.DRAWING_TRENDS.GANN_FAN]: {
            [Types.OBJECT_EDIT_LINE_WIDTH]: {
                path: 'strokeWidth',
                type: 'width',
            },
            [Types.OBJECT_EDIT_LINE_TYPE]: {
                path: 'strokeDasharray',
                type: 'line',
            },
            [Types.OBJECT_EDIT_PROPS]: {
                type: 'settings',
            },
        },
        [Types.DRAWING_TRENDS.RECTANGLE]: {
            [Types.OBJECT_EDIT_STROKE]: {
                path: 'stroke',
                type: 'stroke',
            },
            [Types.OBJECT_EDIT_FILL]: {
                path: 'fill',
                type: 'fill',
            },
            [Types.OBJECT_EDIT_LINE_WIDTH]: {
                path: 'strokeWidth',
                type: 'width',
            },
            [Types.OBJECT_EDIT_PROPS]: {
                type: 'settings',
            },
        },
        [Types.DRAWING_TRENDS.ROTATED_RECTANGLE]: {
            [Types.OBJECT_EDIT_STROKE]: {
                path: 'stroke',
                type: 'stroke',
            },
            [Types.OBJECT_EDIT_FILL]: {
                path: 'fill',
                type: 'fill',
            },
            [Types.OBJECT_EDIT_LINE_WIDTH]: {
                path: 'strokeWidth',
                type: 'width',
            },
            [Types.OBJECT_EDIT_PROPS]: {
                type: 'settings',
            },
        },
        [Types.DRAWING_TRENDS.ROTATED_ELLIPSE]: {
            [Types.OBJECT_EDIT_STROKE]: {
                path: 'stroke',
                type: 'stroke',
            },
            [Types.OBJECT_EDIT_FILL]: {
                path: 'fill',
                type: 'fill',
            },
            [Types.OBJECT_EDIT_LINE_WIDTH]: {
                path: 'strokeWidth',
                type: 'width',
            },
            [Types.OBJECT_EDIT_PROPS]: {
                type: 'settings',
            },
        },
        [Types.DRAWING_TRENDS.ROTATED_TRIANGLE]: {
            [Types.OBJECT_EDIT_STROKE]: {
                path: 'stroke',
                type: 'stroke',
            },
            [Types.OBJECT_EDIT_FILL]: {
                path: 'fill',
                type: 'fill',
            },
            [Types.OBJECT_EDIT_LINE_WIDTH]: {
                path: 'strokeWidth',
                type: 'width',
            },
            [Types.OBJECT_EDIT_PROPS]: {
                type: 'settings',
            },
        },
        [Types.DRAWING_TRENDS.CURVE_LINE]: {
            [Types.OBJECT_EDIT_STROKE]: {
                path: 'stroke',
                type: 'stroke',
            },
            [Types.OBJECT_EDIT_LINE_WIDTH]: {
                path: 'strokeWidth',
                type: 'width',
            },
            [Types.OBJECT_EDIT_LINE_TYPE]: {
                path: 'strokeDasharray',
                type: 'line',
            },
        },
        [Types.DRAWING_TRENDS.ARROW_MARK_UP]: {
            [Types.OBJECT_EDIT_FONT_TYPE]: {
                path: 'font',
                type: 'font',
            },
            [Types.OBJECT_EDIT_STROKE]: {
                path: 'stroke',
                type: 'stroke',
            },
            [Types.OBJECT_EDIT_FONT_SIZE]: {
                path: 'strokeWidth',
                type: 'fontSize',
            },
            [Types.OBJECT_EDIT_PROPS]: {
                type: 'settings',
            },
        },
        [Types.DRAWING_TRENDS.ARROW_MARK_DOWN]: {
            [Types.OBJECT_EDIT_FONT_TYPE]: {
                path: 'font',
                type: 'font',
            },
            [Types.OBJECT_EDIT_STROKE]: {
                path: 'stroke',
                type: 'stroke',
            },
            [Types.OBJECT_EDIT_FONT_SIZE]: {
                path: 'strokeWidth',
                type: 'fontSize',
            },
            [Types.OBJECT_EDIT_PROPS]: {
                type: 'settings',
            },
        },
        [Types.DRAWING_TRENDS.ARROW_MARK_FLAG]: {
            [Types.OBJECT_EDIT_FONT_TYPE]: {
                path: 'font',
                type: 'font',
            },
            [Types.OBJECT_EDIT_STROKE]: {
                path: 'stroke',
                type: 'stroke',
            },
            [Types.OBJECT_EDIT_FONT_SIZE]: {
                path: 'strokeWidth',
                type: 'fontSize',
            },
            [Types.OBJECT_EDIT_PROPS]: {
                type: 'settings',
            },
        },
        [Types.DRAWING_TRENDS.INTERACTIVE_BRUSH]: {
            [Types.OBJECT_EDIT_STROKE]: {
                path: 'stroke',
                type: 'stroke',
            },
            [Types.OBJECT_EDIT_LINE_WIDTH]: {
                path: 'strokeWidth',
                type: 'width',
            },
            [Types.OBJECT_EDIT_LINE_TYPE]: {
                path: 'strokeDasharray',
                type: 'line',
            },
        },
        [Types.DRAWING_TRENDS.TEXT_MARK]: {
            [Types.OBJECT_EDIT_FONT_TYPE]: {
                path: 'font',
                type: 'font',
            },
            [Types.OBJECT_EDIT_STROKE]: {
                path: 'stroke',
                type: 'stroke',
            },
            [Types.OBJECT_EDIT_FONT_SIZE]: {
                path: 'strokeWidth',
                type: 'fontSize',
            },
            [Types.OBJECT_EDIT_PROPS]: {
                type: 'settings',
            },
        }
    },

    indicators: {
        maxInternalCount: 5,
        maxExternalCount: 2,
        popular: [
            '1.1',
            '4.4',
            '3.11',
            '5.6',
            '3.12',
            '1.2',
            '4.6',
            '4.5',
            '4.8',
        ]
    },

    // Парель размеров баров
    availableTimeFrames: {
        "20FS": false,
        "30FS": false,
        "1F": true,
        "3F": true,
        "5F": true,
        "15F": true,
        "30F": true,
        "60F": true,
        "180F": false,
        "240F": true,
        "720F": true,
        "DF": true,
        "WF": true,
        "MF": true,
    },

    // Парель временных интервалов
    availableTimePeriods: {
        "30": false,
        "60": true,
        "180": false,
        "360": true,
        "720": true,
        "D": true,
        "W": true,
        "M": true,
        "3M": true,
        "6M": true,
        "1Y": true,
        "3Y": true,
    },

    tradeModeSwitcher: {
        type: 'dropdown', //toggle or dropdown
    },

    userDocs: {
        fileNameMaxLength: 20,
        fieldsConfig: {
            [Types.FILE_TYPE.identity]: {
                documentId: 'identity-docs',
                documentLabel: "Proof of identity",
                documentRequirements: "Identity document (passport, driver's license, ID card)",
                specialTooltip: "Please go to the \"Profile\" page and fill all the fields in the \"Personal data\" section.",
            },
            [Types.FILE_TYPE.address]: {
                documentId: 'address-docs',
                documentLabel: "Proof of residence",
                documentRequirements: "Utility bill (not older than 3 month)",
                specialTooltip: "Please go to the \"Profile\" page and fill all the fields in the \"Address\" section.",
            },
            [Types.FILE_TYPE.card]: {
                documentId: 'card-docs',
                documentLabel: "Credit card",
                documentRequirements: "A bank card (on the front side of the card there have to bee seen the first 6 digits, the last 4, the expiration date and the name of the owner. On the back you have to close the CVV code)",
                specialTooltip: '',
            },
            [Types.FILE_TYPE.other]: {
                documentId: 'other-docs',
                documentLabel: "Other",
                documentRequirements: "Other documents",
                specialTooltip: '',
            },
            [Types.FILE_TYPE.photoWithPassport]: {
                documentId: 'photo-with-passport',
                documentLabel: "Photo with passport",
                documentRequirements: "Photo with a passport open on the first page / ID card in the hands (the face is clearly visible, the passport is readable)",
                specialTooltip: '',
            },
            [Types.FILE_TYPE.photoCardBackgroundSite]: {
                documentId: 'photo-card-background-site',
                documentLabel: "Your photo holding the card with the website on the background",
                documentRequirements: "The face is clearly visible. Data on a bank card is readable",
                specialTooltip: '',
            },
        },
    },

    /**
     * Maintenance begin message
     */
    mtMessage: {
        enable: false,
        //for 'static' version
        begin: "23:15", // mt begin time UTC+0
        duration: 15, // minutes
    },

    menu: {
        createWallet:  false,
        calendar: false,
        wlNewsPage: false, // enable link to WL news page
        education: false, // enable link to education
        wlMainPage: false, // enable link to WL website

        account: {
            enable: true,
            items: {
                personalData: true,
                createAccount: false,
                interchanges: false,
                activityLog: true,
                marginHistory: true,
                accountSummary: true,
                transactionHistory: false,
                deposit: true,
                withdraw: true,
                buyBxc: false,
                history: true,
                //mobile
                twoFactorAuth: true,
                changePassword: true,
                createWallet: true,
                cabinet: false,
                requestCallback: true,
            }
        },

        // custom2 accounts menu
        deposit: true,
        withdraw: true,
        personalData: true,
        changePassword: true,
        twoFactorAuth: true,
        twoFAEmail: true,
        history: true,
        activityLog: true,
        transactionHistory: true,
        basicSettings: true,
        tradeSettings: true,
        chartSettings: true,
        notificationsAndConfirmations: true,
        requestCallback: true,
        sendEmail: true,
        accessKeys: true,

        reports: {
            enable: true,
            items: {
                depositWithdrawals: false,
                exchangeHistory: false,
                marginHistory: true,
                //mobile
                history: true,
                activityLog: true,
                transactionHistory: true,
            }
        },

        support: {
            enable: true,
            items: {
                faq: true,
                tutorials: true,
                academy: true,
                //mobile
                education: true,
                requestCallback: true,
                sendEmail: true,
                liveSupport: true,
            }
        },
        security: {
            enable: isCustomDesigns ? true : false,
            items: {
                twoFactorAuth: true,
                changePassword: true,
                apiKey: true,
                [Types.SECURITY_WINDOW.CHANGE_PASSWORD]: false,
                [Types.SECURITY_WINDOW.TWO_FA_GOOGLE]: false,
                [Types.SECURITY_WINDOW.TWO_FA_EMAIL]: false,
                [Types.SECURITY_WINDOW.ANTI_PHISHING_CODE]: false,
                [Types.SECURITY_WINDOW.TWO_FA]: false,
                [Types.SECURITY_WINDOW.API_KEY]: false,
            }
        },
        other: {
            enable: true,
            items: {
                instrumentDetails: true,
                generalSettings: true,
            }
        },
        service: {
            enable: false,
            items: {
                mining: true,
                exchange: true,
                investing: true,
                affiliate: true
            }
        },

        referral: true,

        //mobile
        settings: {
            enable: true,
            items: {
                basicSettings: true,
                tradeSettings: true,
                chartSettings: true,
                notificationsAndConfirmations: true,
                security: true
            }
        },

        liveSupport: {
            enable: true,
        },

        help: {
            enable: true,
            items: {
                requestCallback: true,
                education: false,
                about: true,
                sendEmail: true,
            }
        },

        about: true,
        logOut: true,
    },

    // to show oftenSearched in AdvanceSearch add param like - 'category': 'first search request,second,where comma separator',
    advancedSearch: { 
        oftenSearched: {},
    },

    informationTabs: {
        positions: {
            'wlNewsPage': 'right',
            'calendar': 'right',
            'education': 'right',
            'wlMainPage': 'right',
            'requestCallback': 'right',
        } // tabID: 'right'/'left'
    },

    getServerAddress: (address) => {
        const replace = (address) => {
            let url = address;

            try {
                url = new URL(address);
            } catch(e) { }

            if (typeof url !== 'string') {
                url.hostname = url.hostname.replace(AppConfig.domain, document.domain);
                return url.toString();
            }

            return address;
        }

        if (!AppConfig.isEnableDynamicDomain || AppConfig.domain.length === 0 || document.domain === 'localhost') {
            return address;
        }

        if (typeof address === 'string') {
            return replace(address);
        }

        if (Array.isArray(address)) {
            return address.map(item => replace(item));
        }

        if (typeof address === 'object') {
            let addresses = {};

            for (let key in address) {
                addresses[key] = (!address[key])?
                    address[key]:
                    replace(address[key]);
            }

            return addresses;
        }

        return address;
    }
}, envConfig, { arrayMerge: (dest, source) => source });

export { AppConfig };
export default AppConfig;